<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
// https://juejin.cn/post/7356889912127422464
import { ref } from "vue";

export default {
  name: "PdfViewer",
  props: {
    url: {
      type: String,
      required: true,
      default: ""
    },
    loaded: {
      type: Function,
      default: () => {}
    },
    readComplete: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const pdfSrc = `/lib/pdfjs-legacy-dist/web/viewer.html?
file=${encodeURIComponent(props.url)}#page=1`;
    const iframeRef = ref(null);
    const numPages = ref(0);
    return {
      pdfSrc,
      iframeRef,
      numPages
    };
  },
  methods: {
    // 获取PDFJS实例
    getViewerInstance() {
      const {
        PDFViewerApplication,
        PDFViewerApplicationOptions
      } = this.$refs.iframeRef.contentWindow;
      return { PDFViewerApplication, PDFViewerApplicationOptions };
    },

    // 监听PDFJS的各个事件
    actionListenInit(app) {
      app.initializedPromise.then(() => {
        console.log("实例加载完成");
        // 如果内容加载完成，则展示pdf控件
        app.eventBus.on("documentloaded", e => {
          console.log("pdf 内容加载完成");
          this.numPages = e.source.pagesCount;
          console.log("pdf 总页数", this.numPages);
          app.eventBus.dispatch("pagenumberchanged", {
            value: 1
          });
          this.loaded();
        });

        app.eventBus.on("pagerendered", e => {
          if (e.pageNumber >= this.numPages) {
            console.log(`已阅读至最后一页`);
            this.readComplete(true);
          }
        });

        app.eventBus.on("pagechanging", e => {
          const currentPage = e.pageNumber;
          if (currentPage === this.numPages) {
            console.log(`已阅读至最后一页`);
            this.readComplete(true);
          }
        });
      });
    },
    handleMessage(event) {
      if (event.origin === window.origin) {
        if (event.data === "pdfViewerLoad") {
          const PDFViewer = this.getViewerInstance();
          this.actionListenInit(PDFViewer.PDFViewerApplication);
        }
      }
    }
  },
  mounted() {
    // 加载完成之后获取PDFJS实例
    // this.$refs.iframeRef.contentWindow.onload = () => {
    //   const PDFViewer = this.getViewerInstance();
    //   console.log("mac PDFViewer", PDFViewer);
    //   this.actionListenInit(PDFViewer.PDFViewerApplication);
    // };
    console.log("mac mounted");
    window.onmessage = this.handleMessage;
  },
  unmounted() {
    console.log("mac unmounted");
    window.onmessage = null;
  }
};
</script>

<template>
  <div class="pdf-home">
    <iframe :src="pdfSrc" ref="iframeRef" class="pdf-iframe" />
  </div>
</template>

<style scoped>
.pdf-home {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.pdf-iframe {
  height: 100%;
  width: 100%;
  border-width: 0;
}
</style>
