import CookieSet from './cookie';
/** 验证金额: 只能数字、最多12位 */
const format_fund = (value) => value.replace(/[^0-9]+/g, '').substr(0, 12);
/** 验证验证码: 只能数字、最多6位 */
const format_ver_code = (value) => value.replace(/[^0-9]+/g, '').substr(0, 4);
/** 验证邮件: (字母数字_-)中文@(字母数字_-).(字母数字_-)... */
const format_email = (value) => value.replace(/^[A-Za-z0-9_=\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/g, '').substr(0, 6);
/** 验证电话 */
const format_mobile = (value) => value.replace(/[^0-9]+/g, '').substr(0, 12);
/** 验证办公电话: 数字 字母 — + */
// const format_email = (value) => value.replace(//g, '').substr(0, 6);
/** cookie和跳转时间 */
const setCookieValue = (text, expires) => {
  CookieSet.set('phone', text, { expires: new Date(Number(expires)) });
};

// 是否是test环境
const isTestEnv = () => {
  const isDebug = localStorage.getItem('debug'); // 获取本地debug标志
  const { host = '' } = document.location; // host 为开户域名
  const firstDomain = host.split('.')[0]; // 获取子域名
  return isDebug || firstDomain.startsWith('test') || firstDomain.startsWith('localhost'); // 如果是test环境 testcaweb.investmaster.cn
};

const DomainConfig = {
  'testcaweb.investmaster.cn': 'testisdaweb.investmaster.cn', // test 开户域名:isda域名
  'testcawebhk.investmaster.cn': 'testisdawebhk.investmaster.cn', // 外网test 开户域名:isda域名
  'test2caweb.investmaster.cn': 'test2isdawebhk.investmaster.cn',
  'kh.beevestsec.com': 'kh.beevestwm.com', // 线上 开户域名:isda域名
  'acopening.jinluosecurities.com': 'acisweb.jinluosecurities.com', // JL 开户域名:isda域名
  'acopening.zlcap.com.hk': 'acisweb.zlcap.com.hk', // 开户域名无效、ZL 开户域名:isda域名
  'acopening.sinofidescap.com': 'acisweb.sinofidescap.com', //  ZC 开户域名:isda域名
  'localhost:8080': 'localhost:8081',
};

const getIsKH = () => {
  const { host = '' } = document.location;
  return !!Object.keys(DomainConfig).includes(host);
};
const getIsISDA = () => {
  const { host = '' } = document.location;
  return !!Object.values(DomainConfig).includes(host);
};

// 在**开户页面**获取isda域名，从开户跳转而来
const getIsdaHostInKH = () => {
  const { host = '' } = document.location;
  return DomainConfig[host] || '';
};
// 在**开户/ISDA页面**获取开户域名
const getCaHost = () => {
  const { host = '' } = document.location;
  const item = Object.keys(DomainConfig).find((it) => (it === host || DomainConfig[it] === host));
  return item;
};

const getIsdaHost = () => {
  const { host = '' } = document.location;
  const item = Object.keys(DomainConfig).find((it) => (it === host || DomainConfig[it] === host));
  return DomainConfig[item];
};

const callApp = (call) => {
  const url = `forexmaster://fdt/${call}`;
  const iframe = document.createElement('iframe');
  iframe.style.width = '1px';
  iframe.style.height = '1px';
  iframe.style.display = 'none';
  iframe.src = url;

  if (document.body) {
    document.body.appendChild(iframe);
    setTimeout(() => {
      iframe.parentNode.removeChild(iframe);
    }, 100);
  } else {
    window.onload = () => {
      document.body.appendChild(iframe);
      setTimeout(() => {
        iframe.parentNode.removeChild(iframe);
      }, 100);
    };
  }
};
const setPdfName = (name) => name.replace(/\.pdf$/, `${new Date().valueOf()}bvt.pdf`);
const formatPdfName = (name) => name.replace(/\d{13}bvt.pdf$/, '.pdf');
const isMobile = (userAgent = navigator.userAgent) => userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

// 如果是test环境，则返回false 认为是移动端
const isPC = () => !isTestEnv() && !isMobile();

const clearLocalVuex = () => window.sessionStorage.removeItem('vuex');
export default {
  format_fund,
  format_ver_code,
  format_email,
  format_mobile,
  setCookieValue,
  getIsdaHostInKH,
  getCaHost,
  getIsdaHost,
  getIsKH,
  getIsISDA,
  callApp,
  setPdfName,
  formatPdfName,
  isMobile,
  isPC,
  clearLocalVuex,
};
