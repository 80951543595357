<template>
  <div class="home">
    <div class="image">
      <van-image
        width="100px"
        style="padding: 20px"
        height="100px"
        fit="contain"
        :src="imgSrcList[changeState]"
      />
    </div>
    <div class="theme-color">
      <div>{{ this.tip }}</div>
    </div>
    <div style="text-align: left; margin: 20px; word-wrap: break-word">{{ this.rejectTip }}</div>
    <van-button
      v-if="this.isReject"
      type="primary"
      style="width: 80%; margin: auto"
      @click="handleGoCheck"
    >
      重新提交
    </van-button>
    <van-button
      v-else-if="changeState !== 3"
      type="primary"
      style="width: 80%; margin: auto"
      @click="handleGoCheck"
    >
      查看当前有效资料
    </van-button>
    <van-button style="width: 80%; margin: 10px auto" @click="handleGoHome"> 回到首页 </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Button, Steps, Step, Icon } from "vant";
import { useStore } from "vuex";
import { onMounted } from "vue";
import httpApi from "../../utils/httpApi";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Icon.name]: Icon,
  },
  data() {
    return {
      isReject: "",
      tip: "",
      rejectTip: "",
      changeState: -1,
      imgSrcList: [
        "",
        "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/account_processing.png",
        "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/account_processing.png",
        "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/account_success.png",
        "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/evaluation_failed.png",
      ],
    };
  },
  async mounted() {
    const result = await httpApi.changeInfoGetState();
    if (!result) return;
    console.log(result);
    const { changeState, approveInfo } = result.data;
    if (changeState === 1 || changeState === 2) this.tip = "申请已提交，正在审批中";
    if (changeState === 3) this.tip = "审批通过";
    if (changeState === 4) {
      this.tip = "审批退回";
      this.isReject = true;
      this.rejectTip = approveInfo;
    }
    this.changeState = changeState;
  },
  methods: {
    handleGoCheck() {
      this.$router.push({ path: "/change/index" });
    },
    handleGoHome() {
      this.$router.push({ path: "/search/index" });
    },
  },
  setup() {
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "查询审批结果");
      store.commit("setStepsShow", false);
    });
    return {};
  },
};
</script>

<style lang="less">
.message {
  padding: @space-0;
  text-align: left;
  div {
    margin-bottom: @space-1;
  }
}
.step {
  background-color: transparent;
  text-align: left;
  margin-left: calc(@space-0 * 2);
}
.van-step {
  padding-left: @space-1;
}
.circle {
  border: 1px solid red;
  border-radius: 50%;
  text-align: center;
  width: 16px;
  height: 16px;
  .van-icon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: red;
  }
}
.right {
  border: 1px solid green;
  .van-icon {
    color: green;
  }
}
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home {
  padding-left: @space-1;
  padding-right: @space-1;
}
.wraper {
  text-align: left;
}
.btn-card {
  margin-top: 20px;
  text-align: left;
  .title {
    background: white;
    border-bottom: 4px solid #f6f6f6;
    padding: 5px;
    margin: 0px;
  }
  .row {
    display: flex;
    align-items: center;
    padding: 6px 5px;
    background: white;
    border-bottom: 1px solid #f6f6f6;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
</style>
