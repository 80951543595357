<template>
  <div class="info-one">
    <van-cell-group title="基本资料">
      <van-field
        clearable
        v-model="form.cn_name"
        label="姓名(中文)"
        placeholder="非大陆居民选填"
        input-align="right"
        maxlength="100"
      />
      <van-field
        label-width="12em"
        clearable
        v-model="form.en_name"
        placeholder="请输入"
        input-align="right"
        maxlength="100"
      >
        <template #left-icon>
          <div class="label van-field__label" style="width: 140px">
            姓名(英文/拼音)<van-icon class="icon" name="info-o" @click.stop="handleNameTip" />
          </div>
        </template>
      </van-field>
      <van-field
        v-model="form.live_addr"
        input-align="right"
        label="住址"
        placeholder="请输入"
        clearable
        maxlength="100"
        @blur="handleLiveAddrBlur"
      />
      <van-field
        v-model="form.mailing_addr"
        input-align="right"
        label="通讯地址"
        clearable
        placeholder="请输入"
        maxlength="100"
      />
      <van-field
        v-model="form.credentials_type"
        input-align="right"
        label="身份证件种类"
        @click="showSheet('credentials_type')"
        placeholder="请选择"
        right-icon="arrow"
        readonly
        maxlength="100"
      />
      <van-field
        v-model="form.credentials_num"
        input-align="right"
        label="身份证件号码"
        clearable
        placeholder="请输入"
        @blur="handleCheckId"
        maxlength="30"
      />
      <van-field
        v-model="form.issue_at"
        label="签发地"
        input-align="right"
        clearable
        placeholder="请输入"
        maxlength="100"
      />
      <van-field
        v-model="form.marital_status"
        label="婚姻状况"
        @click="showSheet('marital_status')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.sex"
        label="性别"
        @click="showSheet('sex')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.educ"
        label="教育水平"
        @click="showSheet('educ')"
        input-align="right"
        placeholder="请选择"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.born_addr"
        input-align="right"
        clearable
        label="出生地点"
        placeholder="请输入"
        maxlength="100"
      />
      <van-field
        v-model="form.born_time"
        label="出生日期"
        @click="showPopup = true"
        input-align="right"
        placeholder="请选择"
        right-icon="arrow"
        readonly
      />
      <van-field
        label-width="7em"
        v-model="form.citizenship"
        input-align="right"
        clearable
        label="国籍/公民身份"
        placeholder="请输入国家/地区"
        maxlength="100"
      />
      <van-field
        v-model="form.email"
        label="E-mail"
        input-align="right"
        clearable
        placeholder="请输入"
        maxlength="100"
      />
      <van-field
        v-model="form.auth_type"
        label-width="10em"
        input-align="right"
        @click="showSheet('auth_type')"
        placeholder="请选择"
        right-icon="arrow"
        readonly
      >
        <template #left-icon>
          <div class="label van-field__label" style="width: 140px">
            身份认证方式<van-icon class="icon" name="info-o" @click.stop="handleTip" />
          </div>
        </template>
      </van-field>
      <van-field
        maxlength="100"
        clearable
        v-model="form.hk_bank"
        label="开户行"
        input-align="right"
        placeholder="绑定到证券账号，请输入"
      />
      <van-field
        maxlength="100"
        v-model="form.bank_account_name"
        input-align="right"
        label-width="10em"
        clearable
        label="账户名称(英文/拼音)"
        placeholder="请输入英文/拼音账户名称"
      />
      <van-field
        maxlength="100"
        input-align="right"
        v-model="form.bank_account_num"
        clearable
        label="账户号码"
        placeholder="绑定到证券账号，请输入"
      />
    </van-cell-group>
    <van-action-sheet
      v-model:show="isShowSheet"
      :actions="actions"
      @select="onSheetSelect"
      cancel-text="取消"
      close-on-click-action
    />
    <van-popup position="bottom" v-model:show="showPopup">
      <van-datetime-picker
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        v-model="currentDate"
        :min-date="minDate"
        @confirm="onPopupSelect"
        @cancel="showPopup = false"
      />
    </van-popup>
    <div style="width: 100%; text-align: center">
      <van-button type="primary" class="btn" :disabled="disabledNext" @click="next">
        下一步(工作与财务资料)
      </van-button>
      <van-button class="btn" @click="before"> 上一步 </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Dialog,
  Icon,
  CellGroup,
  Field,
  ActionSheet,
  Popup,
  Picker,
  Cell,
  DatetimePicker,
} from "vant";
import { useStore } from "vuex";
import { onMounted, computed, reactive, ref, watch } from "vue";
import httpApi from "../../utils/httpApi";

const credentials_type = [
  { name: "中国大陆身份证" },
  { name: "中国香港身份证" },
  { name: "其它国家/地区身份证" },
  { name: "护照" },
  { name: "其他" },
];
const marital_status = [{ name: "未婚" }, { name: "已婚" }, { name: "离婚" }, { name: "丧偶" }];
const sex = [{ name: "男" }, { name: "女" }];
const educ = [{ name: "小学或以下" }, { name: "中学" }, { name: "大学或以上" }];
const auth_type = [{ name: "香港/合资格银行" }, { name: "线下见证" }];
const alertMsg = `香港/合资格银行：需绑定香港本地银行或合资格司法管辖区银行（美国、新加坡、英国、马来西亚、澳大利亚、奥地利、比利时、加拿大、爱尔兰、以色列、意大利、挪威、葡萄牙、西班牙、瑞典、瑞士），若是专业投资者需要邮寄专业投资者证明。

线下见证：提交申请后，将有认证人员联系并进行线下认证，并需邮寄开户申请签字材料、身份证件复印件、住址证明、银行证明、专业投资者证明（仅限专业投资者）。`;
let submitForm;
export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon,
  },
  data() {
    return {
      isShowDatePick: false,
      isShowSheet: false,
      showPopup: false,
      actionsKey: "",
      actions: [],
      credentials_type,
      marital_status,
      sex,
      educ,
      auth_type,
    };
  },
  methods: {
    handleCheckId() {
      const { credentials_num } = submitForm;
      if (submitForm.credentials_type === "中国大陆身份证") {
        if (credentials_num.length !== 18) {
          this.$toast("请输入18位身份证号");
        } else {
          const end2 = credentials_num[credentials_num.length - 2];
          if (!Number.isNaN(end2)) {
            this.form.sex = Number(end2) % 2 ? "男" : "女";
          }
          const born_date = credentials_num.slice(6, 14);
          this.form.born_time = `${born_date.slice(0, 4)}-${born_date.slice(
            4,
            6
          )}-${born_date.slice(6, 8)}`;
        }
      }
    },
    getTime(val) {
      return val < 10 ? `0${val}` : val;
    },
    formatTime(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${this.getTime(month)}-${this.getTime(day)}`;
    },
    handleNameTip() {
      Dialog.alert({
        title: "提示",
        messageAlign: "left",
        message: "姓名（英文/拼音），所有字母都要大写，且姓与名之间要有空格。",
        confirmButtonColor: "rgba(0,0,0,0.6)",
      });
    },
    handleTip() {
      Dialog.alert({
        title: "身份认证方式",
        messageAlign: "left",
        message: alertMsg,
        confirmButtonColor: "rgba(0,0,0,0.6)",
      });
    },
    handleLiveAddrBlur() {
      if (this.form.live_addr && !this.form.mailing_addr) {
        this.form.mailing_addr = this.form.live_addr;
      }
    },
    showSheet(key) {
      this.isShowSheet = true;
      this.actionsKey = key;
      this.actions = this[`${key}`];
    },
    onSheetSelect(value) {
      if (!this.actionsKey) return;
      this.form[this.actionsKey] = value.name;
      this.isShowSheet = false;
      this.actionsKey = "";
      this.actions = [];
    },
    onPopupSelect(value) {
      this.showPopup = false;
      this.form.born_time = this.formatTime(value);
    },
    async next() {
      if (this.disabledNext.value) return;
      if (!submitForm.en_name.trim().match(/\s/g)) {
        this.$toast("【姓名(英文/拼音)】一栏的姓、名中间需空格分割！");
        return;
      }
      if (
        submitForm.credentials_type === "中国大陆身份证" &&
        submitForm.credentials_num.length !== 18
      ) {
        this.$toast("请输入18位身份证件号码");
        return;
      }
      submitForm.en_name = submitForm.en_name.toUpperCase().replace(/\s+/g, " ");
      const { code, msg } = await this.$api.postInfoStepOne(submitForm);
      if (code === 200) {
        this.$router.push({ path: "/info/step_two" });
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const form = reactive({
      cn_name: "",
      en_name: "",
      live_addr: "",
      mailing_addr: "",
      credentials_type: "",
      credentials_num: "",
      issue_at: "",
      marital_status: "",
      sex: "",
      educ: "",
      born_addr: "",
      born_time: "",
      citizenship: "",
      email: "",
      auth_type: "香港/合资格银行",
      hk_bank: "",
      bank_account_name: "",
      bank_account_num: "",
    });
    const currentDate = ref(new Date());
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "资料填写");
      store.commit("setStepsValue", ["填写资料(1/5)", "风险认知", "提交资料"]);
      store.commit("setStepsActive", 0);
      const result = await httpApi.getInfoStepOne();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (result.data[item]) {
          form[item] = result.data[item];
        }
      });
      currentDate.value = result.data.born_time ? new Date(result.data.born_time) : new Date();
    });
    const currentDateTime = computed(() => currentDate.value.getTime());

    const disabledNext = ref(true);
    watch(form, (val) => {
      submitForm = { ...val };

      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach((item) => {
        if (item !== "cn_name" && !submitForm[item].trim()) {
          disabledNext.value = true;
        }
      });

      httpApi.formLog(submitForm, disabledNext);
    });

    return {
      form,
      disabledNext,
      currentDate,
      currentDateTime,
      minDate: new Date(1920, 0, 1),
      maxDate: new Date(),
    };
  },
};
</script>

<style lang="less">
.info-one {
  text-align: left;
  .check-group {
    width: 200px;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: space-around;
    flex-wrap: wrap;
    & > div {
      flex-basis: 50%;
    }
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
  .icon {
    display: inline;
    position: relative;
    top: 3px;
    left: 3px;
  }
}
</style>
