<template>
  <div class="search">
    <div class="image">
      <van-image
        width="100px"
        style="padding: 20px"
        height="100px"
        fit="contain"
        src="https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/account_processing.png"
      />
    </div>
    <div>已提交!</div>
    <van-button @click="handleGoOpenSubAccount" class="btn" style="margin-top: 30px">
      开立子账户查看
    </van-button>
    <van-button v-if="isdaJump" class="btn" @click="handleGoHome"> 回到首页 </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Button } from "vant";
import { useStore } from "vuex";
import { onMounted } from "vue";
import utils from "../../utils/util";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  data() {
    return {
      isdaJump: this.$store.state.user.isdaJump,
    };
  },
  methods: {
    handleGoOpenSubAccount() {
      this.$router.push({ path: "/isda/openSubAccount" });
    },
    handleGoHome() {
      const url = `${window.location.protocol}//${utils.getCaHost()}/search/index`;
      window.location.replace(url);
    },
  },
  setup() {
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "开立子账户");
      store.commit("setStepsShow", false);
    });
    return {};
  },
};
</script>

<style lang="less">
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search {
  padding-left: @space-0;
  padding-right: @space-0;
  .btn {
    width: 90%;
    margin: 10px auto;
  }
  .back-tip {
    margin-top: 30px;
    text-align: left;
    margin-left: 5%;
  }
}
</style>
