<template>
  <div class="aggrement">
    <div class="content" :key="fileInfo.url" style="min-height: 60vh;">
      <PdfViewer :loaded="loaded" :readComplete="readComplete" :url="fileInfo.url" />
    </div>
    <div @click="handleAcceptAbove">
      <van-checkbox name="是" shape="square" v-model="form.isda_clean" :disabled="disabled">
        <div style="padding: 10px; position: ">本人已经细阅、明白及接受上述文件的条款</div>
      </van-checkbox>
    </div>
    <van-button
      type="primary"
      style="width: 80%"
      class="btn"
      @click="next"
      :disabled="disabledNext"
    >
      下一步
    </van-button>
  </div>
</template>

<script>
import { onMounted, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import { Button, Field, CellGroup, Checkbox, Toast } from "vant";
import httpApi from "../../utils/httpApi";
import PdfViewer from "../../components/PdfViewer.vue";

let submitForm;
export default {
  name: "aggrement",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    PdfViewer,
  },
  data() {},
  beforeCreate() {
    const { query = {} } = this.$route;
    const { c, e } = query;
    const time = localStorage.getItem("cookieExpires") || 0;
    this.$store.dispatch("handleLogin");
    if (Number(e) > Number(time)) {
      this.$store.commit('setIsdaJump', true);
      this.$util.setCookieValue(c, e);
    }
  },
  methods: {
    // PDF加载完成
    loaded() {
      Toast.clear();
    },
    // PDF阅读完成
    readComplete() {
      // 更新数据
      setTimeout(() => {
        this.$api.postAgreementState({
          isda_2002: "是",
        });
      }, 0);
    },
    handleAcceptAbove() {
      if (this.form.isda_clean) {
        ["isda_2002"].forEach((item) => {
          if (this.form[item] !== "是") {
            Toast.clear();
            Toast("请先阅读协议并滚动到底部！");
            this.form.isda_clean = false;
          }
        });
      }
    },
    async next() {
      if (this.disabledNext.value) return;
      if (this.disabled) {
        this.$router.push({ path: "/isda/account" });
      } else {
        const params = JSON.parse(JSON.stringify(this.form));
        params.isda_clean = params.isda_clean ? "是" : "否";
        const result = await this.$api.postAgreementState(params);
        const { code = 201, msg = "操作失败" } = result || {};
        if (code === 200) {
          this.$router.push({ path: "/isda/account" });
        } else {
          this.$toast(msg);
        }
      }
    },
  },
  setup() {
    const fileInfo = reactive({
      url: "https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/isda2002.pdf",
    });
    const form = reactive({
      isda_2002: "", // 2002 ISDA Master Agreement
      isda_clean: false, // 本人/公司已细阅、明白、接受签署上述所有文件条款
    });
    const disabledNext = ref(true);
    const disabled = ref(false);
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "ISDA协议");
      store.commit("setTitleLeft", true);
      store.commit("setStepsShow", false);
      const res = await httpApi.getAgreementState();
      if (res) {
        Object.getOwnPropertyNames(form).forEach((item) => {
          if (item === "isda_clean") {
            form[item] = res.data[item] === "是";
          } else if (res.data[item]) {
            form[item] = res.data[item];
          }
        });
      }
      disabled.value = await httpApi.getIsdaDisabled();
    });
    watch(form, (val) => {
      submitForm = { ...val };
      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach((item) => {
        if (item === "isda_clean") {
          if (!submitForm[item]) disabledNext.value = true;
        } else if (submitForm[item] !== "是") {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });
    return {
      fileInfo,
      form,
      disabledNext,
      disabled,
    };
  },
};
</script>

<style lang="less">
.aggrement {
  height: calc(100vh - 46px);
  box-sizing: border-box;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .btn {
    margin: 0 auto;
    margin-top: 0;
  }
  .scroll {
    flex-basis: calc(100vh - 400px);
    overflow-y: scroll;
    text-align: left;
    margin-bottom: 10px;
  }
  p {
    line-height: 26px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
