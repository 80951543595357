<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

const content = `

Date:          [●]

To:          [●]

Address:     [●]

Email:          [●]

Attention:     [●]

From:          Beevest Wealth Management  Limited（中诚财富管理有限公司）

Address:      [Suite Unit 3905, 39/F, The Center, 99 Queen’s Road Central, Central, Hong Kong]

Email:          customer-service@beevestwm.com

Attention:       Customer Service Department 客服部

Re:     Share Swap and Share Basket Swap Master Confirmation Agreement

Dear Sir/Madam:

The purpose of this Share Swap and Share Basket Swap Master Confirmation Agreement (this “Master Confirmation Agreement”) is to confirm certain general terms and conditions of the Share Swap and/or Share Basket Swap Transactions (each, a “Transaction”) to be entered into between Beevest Wealth Management  Limited（中诚财富管理有限公司） (“Party A” or “Beevest”) and [      ] (“Party B” or “Counterparty”) from time to time and to facilitate the process of entering into and confirming such Transactions. The parties intend that each Transaction shall be a separate Transaction for purposes of the ISDA Master Agreement referred to below. The confirmation applicable to each Transaction, which shall constitute a “Confirmation” for purposes of the ISDA Master Agreement, shall consist of this Master Confirmation Agreement as supplemented by the trade details applicable to such Transaction as set forth in a Transaction Supplement which may be in the form of Annex 1 (Transaction Supplement for Share Swaps) or Annex 2 (Transaction Supplement for Share Basket Swaps) attached hereto or in a different form to which the parties agree, or any amendment to any of the foregoing (each, a “Transaction Supplement”). All provisions contained in this Master Confirmation Agreement govern each Confirmation except as expressly modified in a Transaction Supplement. Notwithstanding the foregoing, the parties acknowledge and agree that the execution of this Master Confirmation Agreement does not require them to document Transactions in accordance with this Master Confirmation Agreement.

This Master Confirmation Agreement, together with any Transaction Supplements, supplements, forms a part of, and is subject to, the ISDA 2002 Master Agreement dated as of [Insert Date], as amended and supplemented from time to time (the “Master Agreement”), between Beevest and Counterparty. All provisions contained in the Master Agreement govern this Master Confirmation Agreement except as expressly modified below.

The definitions and provisions contained in the 2002 ISDA Equity Derivatives Definitions (the “Equity Definitions”) and the 2006 ISDA Definitions (the “2006 Definitions”), each as published by the International Swaps and Derivatives Association, Inc. (“ISDA”), are incorporated into this Master Confirmation Agreement. If in relation to any Transaction there is any inconsistency in terms or definitions, the following will prevail for purposes of such Transaction in the order of precedence indicated: (i) the Transaction Supplement; (ii) this Master Confirmation Agreement; (iii) the Equity Definitions; (iv) the 2006 Definitions; and (v) the Master Agreement.

The general terms of each Share Swap Transaction and Share Basket Swap Transaction to which this Master Confirmation Agreement relates are as follows (unless otherwise specified in the relevant Transaction Supplement), as supplemented by the Transaction Supplement related to such Transaction:

General Terms:

Trade Date:     As specified in the Transaction Supplement.

Effective Date:     As specified in the Transaction Supplement.

Termination Date:     The final Cash Settlement Payment Date or, if later, the final Dividend Payment Date, unless otherwise specified in the Transaction Supplement.

Shares:     In the case of a Share Swap Transaction, as specified in the Transaction Supplement.

In the case of a Share Basket Swap Transaction, as specified in the Share Schedule Addendum to the Transaction Supplement.

Basket:     In the case of a Share Basket Swap Transaction, as specified in the Transaction Supplement.

Exchange:     As specified in the Transaction Supplement.

Related Exchange(s):     All Exchanges.

Hedging Party:     Beevest.

Hypothetical Broker Dealer:     A hypothetical broker dealer subject to the same securities laws and rules and regulations of any securities regulators, exchanges and self-regulating organisations as apply to the Hedging Party or any Affiliate(s) designated by it.


Equity Amounts:

Equity Amount Payer:     Beevest.

Number of Shares:


          As specified in the Transaction Supplement, as reduced in accordance with the provisions regarding partial early termination of the Transaction below.

Number of Baskets:     In the case of a Share Basket Swap Transaction, as specified in the Transaction Supplement, and as reduced in accordance with the provisions regarding partial early termination of the Transaction below.

Equity Notional Amount:     On any day:

     (a)     in respect of a Share Transaction, an amount equal to the product of the Number of Shares and the Initial Price; and

(b)     in respect of a Share Basket Transaction, the sum of the values for each Share in the Basket as the product of the Initial Price of each Share and the relevant Number of Shares comprised in the Basket, multiplied by the Number of Baskets,

in each case unless otherwise specified in the Transaction Supplement.

Type of Return:     Total Return, unless otherwise specified in the Transaction Supplement.

Initial Price:     As specified in the Transaction Supplement.

Final Price:     (a)     in respect of a Share Swap Transaction, the effective price per Share equal to the volume weighted average price that would be realised by a Hypothetical Broker Dealer, acting in a commercially reasonable manner, in terminating or liquidating Applicable Hedge Positions on the Valuation Date or during the Final Execution Period (if the Calculation Agent determines that it would be necessary for a Hypothetical Broker Dealer to terminate or liquidate the entirety of its Applicable Hedge Positions during such Final Execution Period, rather than on the Valuation Date only, in order to achieve such termination or liquidation in a commercially reasonable manner), as adjusted to account for any Transaction Cost, or any other costs, commissions or other fees that may be separately agreed between the parties from time to time and any Transaction Taxes, and converted, if applicable, in accordance with the FX Provisions; and

(b)     in respect of a Share Basket Transaction, an amount for the Basket equal to the sum of the values for the Shares of each Issuer as the product of (i) the effective price per Share as determined pursuant to paragraph (a) above; and (ii) the relevant Number of Shares comprised in the Basket,

     in each case as determined by the Hedging Party in its sole discretion and notified by the Hedge Party to Counterparty in writing.

     For the purposes of this Master Confirmation Agreement:

     “Applicable Hedge Positions” means, at any time, Hedge Positions that the Hedging Party determines that a Hypothetical Broker Dealer, acting in a commercially reasonable manner, would consider necessary to hedge the equity price risk and dividend risk of entering into and performing its obligations with respect to the relevant Transaction (or the relevant portion of the Transaction) at that time.

     “Final Execution Period” means the period from (and including) the Valuation Date to (and including) the earliest date by which a Hypothetical Broker Dealer could terminate or liquidate the entirety of its Applicable Hedge Positions in a commercially reasonable manner (such later date, the “Final Execution End Date”). For the avoidance of doubt, upon the occurrence of any event as provided under the Additional Termination Events section, “Final Execution Period” shall mean the period from (and including) the date by which a Hypothetical Broker Dealer determines, after the occurrence of the relevant  event and in a commercially reasonable manner, that it would be necessary to terminate or liquidate its Applicable Hedge Positions to (and including) the earliest date by which a Hypothetical Broker Dealer could terminate or liquidate the entirety of its Applicable Hedge Positions in a commercially reasonable manner (such later date, the “Final Execution End Date”).

Valuation Time:     In respect of the Valuation Date or each day in the Final Execution Period, as the case may be, the Valuation Time shall be each of the times at which a Hypothetical Broker Dealer, acting in good faith and in a commercially reasonable manner, would terminate or liquidate Applicable Hedge Positions for the purpose of determining the relevant Final Price (as determined by the Calculation Agent).

Valuation Date:     As specified in the Transaction Supplement.

Initial Exchange Amount payable by Party B:

Party B Initial Exchange Amount:     As specified in the Transaction Supplement.

Party B Initial Exchange Date:     As specified in the Transaction Supplement.

Final Exchange Amount payable by Party A:

Party A Final Exchange Amount:     An amount equal to the Party B Initial Exchange Amount.

Party A Final Exchange Date:     Termination Date, without regard to the reference to “the Final Dividend Payment Date” therein.

Fixed Amounts:

Fixed Rate Payer:     Counterparty.

Notional Amount:     An amount equal to the Equity Notional Amount minus the Party B Initial Exchange Amount, unless otherwise specified in the Transaction Supplement.

Fixed Rate Payer Payment Date(s):     As specified in the Transaction Supplement

Fixed Rate:     As specified in the Transaction Supplement.

Fixed Rate Day Count Fraction:     As specified in the Transaction Supplement.

Settlement Terms:

Cash Settlement:     Applicable.

Settlement Currency:     As specified in the Transaction Supplement.

Cash Settlement Payment Date(s):     The date that is one Settlement Cycle, or such number of Currency Business Days as specified in the Transaction Supplement, following the Valuation Date or, if later, the Final Execution End Date; provided that if, in each case, that date is not a Currency Business Day, the next following Currency Business Day.

FX Provisions:     If, with respect to a Transaction, the currency in which any Dividend Amount or Final Price is calculated or determined is different from the Settlement Currency, the Calculation Agent shall determine the value of that amount or price in the Settlement Currency, taking into consideration all available information that it considers relevant, which information shall include the rate(s) of exchange which it determines would apply if that amount or price were converted into the Settlement Currency by a Hypothetical Broker Dealer acting in a commercially reasonable manner.

Dividends (the following provisions shall apply unless the Type of Return is not Total Return):

Dividend Period:     Second Period, provided that for the purposes of determining the Second Period in respect of the final Dividend Period, the Valuation Date in respect of a particular portion of the Applicable Hedge Positions shall be deemed to be such date or dates on which such portion would be terminated or liquidated by a Hypothetical Broker Dealer as determined under the provisions of “Final Price” above.

Dividend Amount:     In respect of the Shares, the related Dividend Period and the related Dividend Payment Date, the product of (i) the Ex Amount (minus any tax (including, without limitation, any transfer or registration stamp duties or taxes, capital gains taxes, withholding taxes or similar taxes, to other extent applicable), fees (including, without limitation, any brokerage commissions, stock exchange or clearing system charges, levies or other similar charges) and any other transaction costs that may be applicable to the payment of Dividend Amount by the Issuer to the Hypothetical Broker Dealer), and (ii) the Number of Shares (or, in the case of a Share Basket Swap Transaction, the relevant Number of Shares multiplied by the Number of Baskets), converted, if applicable, in accordance with the FX Provisions.

Dividend Payment Date(s):     Means, in respect of a Dividend Amount, the date falling on or before the date that is [four] Currency Business Days following the day on which the Issuer of the Shares pays the relevant dividend to holders of record of the Shares.

Dividend Recovery:     If (i) the amount actually paid or delivered by the Issuer to holders of record of the Shares in respect of any gross cash dividend declared by the Issuer to holders of record of the Shares (a “Declared Dividend”) is not equal to that Declared Dividend (a “Dividend Mismatch Event”) or (ii) the Issuer fails to make any payment or delivery in respect of that Declared Dividend by the third Currency Business Day following the relevant due date, then in either case the Calculation Agent may (but is not obliged to) determine:

     (a)     any appropriate adjustment or repayment to be made by a party to account for that Dividend Mismatch Event or non-payment or non-delivery, as the case may be;

(b)     the date that repayment should be made or the effective date of such adjustment, as applicable; and

(c)     any interest payable on any repayment amount.

If the Calculation Agent determines that such a repayment, or an interest payment should be made by a party, the amount so determined shall be payable on the date specified by the Calculation Agent. The provisions of this section (Dividend Recovery) shall apply and remain in full force and effect even if the Termination Date has occurred.

Adjustments:

Method of Adjustment:     Calculation Agent Adjustment. In its determinations of the existence and extent of any diluting or concentrative effect on the theoretical value of the Shares of any Potential Adjustment Event, and any related adjustments to the terms of the Transaction, the Calculation Agent shall take into account the implication of any Transaction Taxes in connection with such Potential Adjustment Event.

Extraordinary Events:

Consequences of Merger Events:     Share-for-Share:     Calculation Agent Adjustment.

     Share-for-Other:     Calculation Agent Adjustment.

     Share-for-Combined:     Calculation Agent Adjustment.

Merger Event Date:     Section 12.1(b) of the Equity Definitions shall be amended by replacing the words “Merger Date” in the fourth last line thereof with the words “Merger Event Date”.

     Sections 12.2(b) and 12.2(e) of the Equity Definitions shall be amended by replacing the words “Merger Date” each time they appear with the words “Merger Event Date”.

     “Merger Event Date” means, in respect of a Merger Event, the date of the occurrence of such Merger Event, which shall be deemed to be the Announcement Date or such other date as the Calculation Agent determines is commercially reasonable in the circumstances.

Tender Offer:     Applicable.

Consequences of Tender Offers:     Share-for-Share:     Calculation Agent Adjustment.

     Share-for-Other:     Calculation Agent Adjustment.

     Share-for-Combined:     Calculation Agent Adjustment.

     Tender Offer Date:  Sections 12.3(a) and 12.3(d) of the Equity Definitions shall be amended by replacing the words “Tender Offer Date” each time they appear with the words “Tender Offer Event Date”.

     “Tender Offer Event Date” means, in respect of a Tender Offer, the date of the occurrence of such Tender Offer, which shall be deemed to be the Announcement Date or such other date as the Calculation Agent determines is commercially reasonable in the circumstances.

Nationalisation, Insolvency or Delisting:     Cancellation and Payment, or in respect of a Share Basket Swap Transaction, Partial Cancellation and Payment.

[Credit Support Provisions:

The provisions in this “Credit Support Provisions” section would only apply to a Transaction if the Initial Exchange Amount is lower than the Equity Notional Amount.

Credit Provisions:      To the extent applicable, none of the Transactions entered into under this Master Confirmation Agreement is a “Covered Transaction” for the purposes of the Credit Support Annex dated as of [●] between the parties or any Credit Support Annex that may be entered into by the parties from time to time and therefore any Transactions entered into under this Master Confirmation Agreement should be ignored for the purposes of determining "Exposure" under such Credit Support Annex.

Closing Price:     In respect of a Scheduled Trading Day, the official closing price per Share published by the Exchange for that Scheduled Trading Day, provided that if such Scheduled Trading Day is a Disrupted Day, then the Calculation Agent may determine its good faith estimate of the price per Share on that Disrupted Day (and the amount so determined shall be the Closing Price for that day).

LTV Ratio:     As of any date, the ratio (expressed as a percentage, and subject to a minimum of zero) calculated as the quotient of:

( A + B + C ) /  ( A + B )



     Where:

“A” means the Initial Exchange Amount;

“B” means the Notional Amount; and

“C” means with respect to a day during the term of the Transaction, the amount, if any, that would be payable by one party to the other, determined by the Calculation Agent as being an amount equal to the difference between (a) the sum of the Equity Amount, any accrued but unpaid Dividend Amount(s) and any Cash Collateral Balance, and (b) the sum of any accrued but unpaid Fixed Amounts and any other amounts that are payable (and reman unpaid) by Party B to Party A under or in connection with the Transaction as of the relevant day, on the basis that:

(i)     for the purpose of determining the value of “C” only:

(a)the immediately previous Scheduled Trading Day shall be the Valuation Date;

(b)the Final Price shall be calculated by the Calculation Agent by reference to the Closing Price as of the Valuation Date,

(ii)     the amount that that would be payable by Party A to Party B shall be expressed as a positive number; and the amount that would be payable to Party A by Party B shall be expressed as a negative number; and

(iii)     if applicable, such amount shall be converted in accordance with the FX Provisions.

Cash Collateral Balance:     At any time, the aggregate Margin Call Amounts that have already been received by Beevest with respect to the relevant Transaction under the Credit Support Provisions minus the aggregate Margin Return Amounts that have been paid by Beevest at that time.

No interest shall accrue on the Cash Collateral Balance.

Margin Call:     (a)     If, as of any Scheduled Trading Day, the Calculation Agent determines that the LTV Ratio is equal to or below the Margin Call Level (each such day, a “Margin Call Date”), the Calculation Agent may deliver a notice substantially in the form set out in Annex 4 (Form of Margin Call Notice) (each such notice, a “Margin Call Notice”) to Counterparty requiring Counterparty to transfer Cash in an amount (the “Margin Call Amount”) equal to the amount which, if added to the Cash Collateral Balance as at the Margin Call Date, would have resulted in the LTV Ratio (calculated with reference to the Closing Price on the Margin Call Date) being equal to (or as close as practicable to, but not lower than) the Initial LTV Level. Such Margin Call Notice may be delivered at any time on or after the Margin Call Date and, notwithstanding Section 12 (Notices) of the Agreement (as may be amended by the parties from time to time), shall be effective on the date it is delivered by Beevest as long as the delivery is made before 11:59 p.m. (Hong Kong time) on such date (such date, the Margin Call Notice Date”).

     (b)     (x) On the Business Day following a Margin Call Notice Date, Counterparty shall by no later than [2:00 p.m.] (Hong Kong time) on such Business Day transfer to Beevest an amount at least equal to the Margin Call Amount; and (y) on the second Business Day following a Margin Call Notice Date, Counterparty shall by no later than [2:00 p.m.] (Hong Kong time) deliver to Beevest evidence (“Evidence of Margin Transfer”) in form and substance satisfactory to Beevest that appropriate transfer has been made to the account specified by Beevest and in accordance with the manner as may be agreed by the parties from time to time.

Margin Return:     (a)     If, as of any Scheduled Trading Date (such date the “Margin Return Calculation Date”), the Calculation Agent determines that the LTV Ratio on such Scheduled Trading Day and the LTV Ratio on each of the [three] consecutive Schedule Trading Days immediately preceding such Margin Return Calculation Date is higher than the Margin Return Level, Counterparty may deliver to Beevest a notice substantially in the form set out in Annex 5 (Form of Margin Return Notice) (each such notice, a “Margin Return Notice”) requesting that Beevest return to Counterparty an amount equal to the lower of:

     (i)     the amount which, if subtracted from the Cash Collateral Balance, would result in the LTV Ratio (calculated with reference to the Share Collateral Balance on the Margin Return Calculation Date) being equal to (or as close as practicable to, but not less than) the Initial LTV Level on the Margin Return Calculation Date; and

     (ii)     the Cash Collateral Balance on the Margin Return Calculation Date,

(such amount being the “Margin Return Amount”).

     (b)     Beevest will, by no later than [5:00 p.m.] (Hong Kong time) on the fifth Business Day following receipt by Beevest of the Margin Return Notice (the “Margin Return Date”), make a payment equal to the relevant Margin Return Amount, provided that:

     (i)     the Margin Return Notice is received by Beevest no later than [4:00 p.m.] (Hong Kong time) on the Business Day following the Margin Return Calculation Date;

(ii)     no Blocking Event has occurred and is continuing or would occur as a result of such payment;

(iii)     the Margin Return Amount is a minimum of [insert currency and amount]; and

     (iv)     the Calculation Agent is satisfied that, following the withdrawal, the LTV Ratio will not fall below the Initial LTV Level (using the latest Closing Price) and that the Margin Return Amount does not exceed the Cash Collateral Balance.



Initial LTV Level:     As specified in the Transaction Supplement.

Margin Call Level:     As specified in the Transaction Supplement.

Margin Return Level:     As specified in the Transaction Supplement.

Transfer of Title:     Counterparty acknowledges and represents that all right, title and interest in the Margin Call Amounts (if any) which Counterparty transfers to Beevest under these Credit Support Provisions will vest in Beevest free and clear of any liens, claims, charges or encumbrances or any other interests of Counterparty or of any third person. Each transfer of the Margin Call Amounts will be made so as to constitute or result in a valid and legally effective transfer of Counterparty’s legal and beneficial title in such Margin Call Amounts to Beevest.

The parties agree that they do not intend to create in favour of either party any mortgage, charge, pledge, lien, encumbrance or other security interest in any Margin Call Amounts transferred by Counterparty to Beevest under these Credit Support Provisions.

Additional Disruption Events:

Change in Law:     Applicable.

     Insofar as it relates to a Transaction to which this Master Confirmation Agreement relates, Section 12.9(a)(ii) of the Equity Definitions is replaced with the following:

     “(ii) “Change in Law” means that, on or after the Trade Date of a Transaction (A) due to the adoption of or any change in any applicable law or regulation (including, without limitation, any tax law) or (B) due to the promulgation of or any change in the interpretation by any court, tribunal or regulatory authority with competent jurisdiction of any applicable law or regulation (including any action taken by a taxing authority), the Calculation Agent determines that it will, or there is a substantial likelihood that it will, within the next 30 calendar days become, or it has become, illegal for a party to the Transaction to hold, acquire or dispose of Hedge Positions relating to the Transaction. However, this Section 12.9(a)(ii) shall not apply if the Calculation Agent determines that such party could have taken reasonable steps to avoid such illegality.”

Insolvency Filing:     Applicable, provided that the definition of “Insolvency Filing” in Section 12.9 of the Equity Definitions shall be amended by deleting the words “provided that such proceedings instituted or petitions presented by creditors and not consented to by the issuer shall not be deemed an Insolvency Filing” at the end of such definition and replacing them with the following:

     “; or it has instituted against it a proceeding seeking a judgment of insolvency or bankruptcy or any other relief under any bankruptcy or insolvency law or other similar law affecting creditors’ rights, or a petition is presented for its winding-up or liquidation by a creditor and such proceeding is not dismissed, discharged, stayed or restrained in each case within fifteen (15) days of the institution or presentation thereof.”.

Hedging Disruption:     Applicable, provided that:

(a)     Section 12.9(b)(iii) of the Equity Definitions shall be amended by inserting in the third line thereof, after the words “to terminate the Transaction”, the words “or a portion of the Transaction affected by such Hedging Disruption”; and

(b)     Section 12.9(a)(v) of the Equity Definitions is replaced with the following:

 “(v) “Hedging Disruption” means that the Hedging Party is unable, after using commercially reasonable efforts, to (A) acquire, establish, re-establish, substitute, maintain, unwind or dispose of any transactions or assets (including, without limitation, stock loans and other transactions that can be used to create a long or short exposure to the Shares) that hedge, in a commercially reasonable manner, based on prevailing circumstances applicable to the Hedging Party, the equity price risk and dividend risk of entering into and performing its obligations with respect to the Transaction (any such transactions or assets, a “Hedging Party Hedge”) or (B) realise, recover or remit the proceeds of a Hedging Party Hedge. However, any such inability that occurs solely due to the deterioration of the creditworthiness of the Hedging Party shall not be a Hedging Disruption.”

Increased Cost of Hedging:     Applicable, provided that Section 12.9(a)(vi) of the Equity Definitions is replaced with the following:

“(vi) “Increased Cost of Hedging” means that the Hedging Party would incur a materially increased (as compared with the circumstances that existed on the Trade Date) amount of tax, duty, expense or fee (other than brokerage commissions) (which amount of tax shall include, without limitation, any amount of tax due to any increase in tax liability, decrease in tax benefit or other adverse effect on its tax position in relation to dividends) (a “Hedging Cost”) to (A) acquire, establish, re-establish, substitute, maintain, unwind or dispose of  the Hedging Party Hedge or (B) realise, recover or remit the proceeds of the Hedging Party Hedge. However, any such materially increased amount that is incurred solely as a result of the deterioration of the creditworthiness of the Hedging Party shall not be an Increased Cost of Hedging.”

Determining Party:     Beevest (for all Extraordinary Events).

Partial Termination for Baskets:     Notwithstanding Section 12.9(b) of the Equity Definitions, if the relevant Transaction is a Share Basket Swap Transaction, the consequence associated with each Additional Disruption Event is hereby amended such that the right to terminate the Transaction is limited to that portion of the Transaction represented by Shares affected by the relevant Additional Disruption Event and, if any such right to partially terminate is exercised, (a) the Cancellation Amount will be calculated with respect solely to the terminated portion, (b) the remainder of the Transaction will continue with the Basket comprising Shares that are not affected by the relevant Additional Disruption Event, and (c) the Calculation Agent will adjust any relevant terms if necessary to preserve as nearly as practicable the economic terms of the Transaction for the remaining Basket.

Cancellation Amount:     Insofar as they apply to a Transaction to which this Master Confirmation Agreement relates, Sections 12.7 and 12.8 of the Equity Definitions are replaced with the following:

     “Section 12.7. Payment upon Certain Extraordinary Events.

     If, in respect of an Extraordinary Event, “Cancellation and Payment” applies or is deemed to apply to the Transaction (or part of it), then an amount shall be paid by one party to the other, determined as provided in Section 12.8. Such payment shall be made not later than three Currency Business Days following the date that notice of the determination by the Calculation Agent of such amount and the party by which it is payable is effective. Such notice shall be provided promptly following the determination.

     Section 12.8. Cancellation Amount.

     “Cancellation Amount” means the amount that would be payable by one party to the other, determined by the Calculation Agent as being an amount equal to (a) the sum of the Equity Amount, the Final Exchange Amount, any accrued but unpaid Dividend Amount(s) and any Cash Collateral Balance, less (b) any accrued but unpaid Fixed Amounts, (if applicable) any Break Funding Cost incurred by Beevest, Delayed Performance Interest, Recovery Service Fee and any other amounts payable by Party B to Beevest but remain unpaid, on the basis that:

     (i)     subject to (ii) below, the first day on which a Hypothetical Broker Dealer, acting in a commercially reasonable manner, would terminate or liquidate any Applicable Hedge Positions (or the relevant part of them) as a result of the termination, or the cancellation, of the Transaction (or the relevant part of it), as determined by the Calculation Agent, is the Valuation Date and the final day of the final Dividend Period in respect of the Transaction (or, as the case may be, the part of it that has been terminated or cancelled); and

     (ii)     if the Transaction is not terminated or cancelled in full, then the Number of Shares (or, for a Share Basket Swap Transaction, the Number of Baskets) for the purposes of determining the Cancellation Amount shall equal the number of Shares or Baskets in respect of which the Transaction is terminated or is cancelled and the Transaction shall continue unaffected by the partial termination or cancellation, but only in relation to a Number of Shares (or, for a Share Basket Swap Transaction, the Number of Baskets) equal to (a) the Number of Shares (or, for a Share Basket Swap Transaction, the Number of Baskets) immediately prior to the partial termination or cancellation less (b) the number of Shares or Baskets in respect of which the Transaction is terminated or cancelled.

     For the purposes of this Section 12.8, the definition of Applicable Hedge Positions shall be amended by the addition of the following at the end thereof:

     “(or, in relation to the determination of any partial Cancellation Amount, a pro rata portion of such Hedge Positions).”

Close-out Amount:     Notwithstanding anything to the contrary in the Agreement or this Master Confirmation Agreement, with respect to a Transaction to which this Master Confirmation Agreement relates, and to the extent such method of determination is permitted by applicable laws or regulations, Close-out Amount with respect to such terminated Transaction shall mean the amount that would be payable by one party to the other, determined by the Calculation Agent as being an amount equal to (a) the sum of the Equity Amount, the Final Exchange Amount, any accrued but unpaid Dividend Amount(s) and any Cash Collateral Balance, less (b) any accrued but unpaid Fixed Amounts and (if applicable) any Break Funding Cost incurred by Beevest, Delayed Performance Interest, Recovery Service Fee and any other amounts payable by Party B to Beevest but remain unpaid,  provided that, the first day on which a Hypothetical Broker Dealer, acting in a commercially reasonable manner, would terminate or liquidate any Applicable Hedge Positions (or the relevant part of them) as a result of the termination of the Transaction, as determined by the Calculation Agent, is the Valuation Date and the final day of the final Dividend Period in respect of the Transaction.

     The Parties acknowledge and agree that to the extent Beevest (or its Affiliates) enters into swaps, forwards, options or futures or other derivatives transaction(s) (“Hedge Transaction”) with any party in any jurisdiction (“Hedge Counterparty”) as Applicable Hedge Positions to hedge its obligations under this Transaction and the Hedge Counterparty for such transactions fails or delays for any reason (including a default by the local exchange or clearinghouse, a change in laws, rules or regulations applicable to the holding of the relevant securities, options or futures contracts) to make any payment or delivery with respect to a Hedge Transaction, then Beevest’s obligation to pay any amounts due hereunder shall be reduced and/or delayed accordingly.

Non-Reliance:     Applicable.

Agreements and Acknowledgements Regarding Hedging Activities:     Applicable.

Additional Acknowledgements:     Applicable.

Optional Early Termination:

The following optional early termination provisions will apply to the Non-Hedging Party and will not apply to the Hedging Party:

(i)If this provision applies to a party (the “OET Electing Party”), the OET Electing Party may elect to terminate the Transaction in whole or in part on any Scheduled Trading Day prior to the Valuation Date by giving the other party notice orally or in writing (a “Termination Notice”) specifying the number of Shares (or number of Baskets) in respect of which it wishes to terminate the Transaction (the “Terminated Number of Shares” or “Terminated Number of Baskets”) and the proposed early termination date. The Optional Early Termination Date shall be the first day falling on or after such proposed early termination date on which a Hypothetical Broker Dealer, acting in a commercially reasonable manner, would terminate or liquidate the Applicable Hedge Positions (as determined by the Calculation Agent) and the Calculation Agent shall notify the parties of such Optional Early Termination Date as soon as reasonably practicable.

(ii)If a Termination Notice is given, subject to paragraphs (iii) and (iv) below, the Final Exchange Amount, the Equity Amount, the Fixed Amount and the Dividend Amount shall be as provided herein but on the basis that the Optional Early Termination Date is deemed to be the Valuation Date and the final day of the final Dividend Period and, if the Terminated Number of Shares or Terminated Number of Baskets is less than the Number of Shares or Number of Baskets (as the case may be), on the basis that references herein to the “Number of Shares”, or, for a Share Basket Swap Transaction, the “Number of Baskets”, are deemed to be references to the Terminated Number of Shares or Terminated Number of Baskets and the definition of “Applicable Hedge Positions” is deemed to be amended by the addition of the phrase “(or in relation to partial optional early termination) a pro rata portion of such Hedge Positions” at the end thereof.

(iii)If a Termination Notice is given in respect of which the Terminated Number of Shares or Terminated Number of Baskets is less than the Number of Shares, or, for a Share Basket Swap Transaction, the Number of Baskets, the Transaction shall continue in effect, but only in relation to a Number of Shares or Number of Baskets equal to (a) the Number of Shares or Number of Baskets immediately prior to the Optional Early Termination Date less (b) the Terminated Number of Shares or Terminated Number of Baskets (as the case may be).

(iv)The Fixed Amount shall be adjusted to account for any Break Funding Costs of the Hedging Party, as determined by the Calculation Agent.

Notice and Account Details:

As specified in the Master Confirmation Agreement:

Contact Details for Notices:

Beevest:     customer-service@beevestwm.com

Counterparty:     [●].

Payment Instructions:

Beevest:     As specified in the Transaction Supplement.

Counterparty:     As specified in the Transaction Supplement.

Specified Offices for Beevest:     Hong Kong

Specified Offices for Counterparty:      [●].

Calculation Agent:     Beevest. The Calculation Agent is responsible for making all determinations under each Transaction that are not expressed to be the responsibility of an identified party.

China Connect:     Unless otherwise specified in the relevant Transaction Supplement, the following provisions shall apply:

     (a)Additional Provisions for Shares traded through the China Connect Service as published by ISDA on 14 October 2014 (available on ISDA’s website through the link below: https://www.isda.org/book/additional-provisions-for-shares-traded-through-the-china-connect-service/) are incorporated into and apply to this Master Confirmation Agreement.

(b)For the purpose of the Additional Provisions for Shares traded through the China Connect Service:

a.China Connect Share Disqualification: Hedging Party.

b.China Connect Service Termination:  Hedging Party.

     Notwithstanding anything to the contrary in this Master Confirmation Agreement, a Hedging Disruption shall be deemed to have occurred with respect to a Transaction if, in circumstances where a shareholding limit under applicable laws is or may be breached or exceeded, The Stock Exchange of Hong Kong Limited imposes a forced sale of any Hedge Positions.

Additional Provisions:     Voting Rights: Notwithstanding any provision of the Equity Definitions, Counterparty shall not acquire any right to vote or to give any consent with respect to any Shares by virtue of any Transaction. Without limiting the generality of the foregoing, Counterparty shall not be entitled pursuant to any Transaction, whether directly or indirectly, to vote or direct the voting of, or to give or direct the giving of any consent with respect to, any Shares (including any Shares held by or on behalf of Beevest as a hedge for the Transaction), and the parties agree that Beevest shall not take any such directions or instructions from Counterparty, or any of its officers, directors, employees, agents or representatives (as applicable), as to such voting or consent.

Additional Termination Events:

Each of the following events will be an Additional Termination Event with respect to the relevant Transaction under this Master Confirmation Agreement and Counterparty shall be the sole Affected Party and the relevant Transaction (or the relevant portion of the Transaction) will be the Affected Transaction:

(a)Any failure by the Issuer to pay any amount (including cash collateral, interest payment or financing amount) exceeding the threshold amount of USD [20,000,000] and such failure to pay is not remedied within [thirty (30)] calendar days.

(b)The Issuer disposes any of its core business activities (whether in whole or a material part thereof), provided that an early termination will only be triggered on or after [ninety (90)] days following such disposal by the Issuer of its core business activities.

(c)A Disrupted Day has occurred in respect of the Shares for at least three (3) consecutive Scheduled Trading Days.

(d)[An Issuer Change of Control. For the purpose of this sub-paragraph, “Issuer Change of Control” means the actual controller of the Issuer as of the Trade Date:

(i)ceases to control the Issuer, where “control” means the power to direct the management and policies of an entity, whether through the ownership of voting capital, by contract or otherwise; or

(ii)disposes of a material portion of his direct or indirect holdings in the Issuer which results in him ceasing to be the largest shareholder of the Issuer after such disposal.]

(e)The Closing Price of the Shares has dropped by [10%] or more from such Closing Price on the immediately previous Scheduled Trading Day for three (3) consecutive Scheduled Trading Days.

(f)Any failure by Counterparty to deliver to Beevest the relevant Margin or the relevant Evidence of Margin Transfer pursuant to the terms of this Master Confirmation Agreement.

(g)Any other situation that may require termination of the relevant Transaction, as determined by Beevest in good faith and in a commercially reasonable manner.

Without prejudice to other rights and available remedies Beevest may have under law and this Agreement and notwithstanding any other provisions to the contrary in the Agreement, Beevest, without any prior notice to Counterparty, may determine in its sole discretion to (x) terminate or liquidate Applicable Hedge Positions in part or in full; and (y) terminate relevant Transaction in part or as a whole.

If Beevest elects to terminate the Transaction in whole or in part in accordance with the provisions of this “Additional Termination Events”:

(i)Beevest shall (after taking into account of the date of actual liquidation of the relevant positions) determine, in good faith and in a commercially reasonable manner, the early termination date of the relevant Transaction or the relevant portion of the Transaction (“Additional Early Termination Date”), and (in the case of partial termination) the number of  Shares (or number of Baskets) in respect of which it wishes to terminate the Transaction (the “Additional Early Terminated Number of Shares” or “Additional Early Terminated Number of Baskets”). Beevest shall notify Party B as soon as reasonably practicable after its determination.

(ii)Subject to paragraphs (iii) and (iv) below, the Final Exchange Amount, the Equity Amount, the Fixed Amount and the Dividend Amount shall be determined pursuant to the terms of this Master Confirmation Agreement, provided that the Additional Early Termination Date shall be deemed to be the final day of the final Dividend Period and, if the Additional Early Terminated Number of Shares or Additional Early Terminated Number of Baskets is less than the Number of Shares or Number of Baskets (as the case may be), references herein to the “Number of Shares”, or, for a Share Basket Swap Transaction, the “Number of Baskets”, shall be deemed to be references to the Additional Early Terminated Number of Shares or Additional Early Terminated Number of Baskets and the definition of “Applicable Hedge Positions” is deemed to be amended by the addition of the phrase “(or in relation to partial early termination) a pro rata portion of such Hedge Positions” at the end thereof.

(iii)If the Additional Early Terminated Number of Shares or Additional Early Terminated Number of Baskets is less than the Number of Shares, or, for a Share Basket Swap Transaction, the Number of Baskets, the Transaction shall continue in effect, but only in relation to a Number of Shares or Number of Baskets equal to (a) the Number of Shares or Number of Baskets immediately prior to the Additional Early Termination Date less (b) the Additional Early Terminated Number of Shares or Additional Early Terminated Number of Baskets (as the case may be).

(iv)The Fixed Amount shall be adjusted to account for any Break Funding Costs of the Hedging Party, as determined by the Calculation Agent.


Delayed Performance Interest

Party B shall pay to Beevest the default interest in relation to any amounts due and payable but not paid under this Agreement (the “Delayed Performance Interest”). The calculation period of the Delayed Performance Interest with respect to the relevant amount is the date from (and including) the due date to (and including) the date on which such amount is fully paid. During the Calculation Period the Delayed Performance Interest is calculated in accordance with the Default Rate.

The definition of Default Rate in the Master Agreement shall be replaced with the following definition:

Default Rate is [●], and in the case that it is higher than the rate permitted by the applicable law, Default Rate shall be the highest rate as permitted by the applicable law.

In the event of a conflict between the provisions of this “Delayed Performance Interest” section and any provisions of the Master Agreement regarding “Default Rate”, this section shall prevail.


Recovery Service Fee

Party B acknowledges and agrees, failure by Party B to transfer Margin Call Amount in full in accordance with the Margin Call Notice in three (3) Business Days after the Martin Call Notice becomes effective will result in certain amount of recovery service fee incurred by Beevest for recovery of corresponding Margin Call Amount (“Recovery Service Fee”). Party B shall indemnify the Recovery Service Fee incurred by Beevest. Unless there is a reasonable reason accepted by Beevest. Party B acknowledges and agrees the Recovery Service Fee is 0.13% of the Equity Notional Amount for each calendar day.

For the avoidance of doubt, Parties acknowledge and agree, Recovery Service Fee is an additional compensation payable by Party B on top of any Delayed Performance Interest or other fees as agreed in this Agreement.



Additional representations, warranties and undertakings by Party B:

In addition to the relevant representations, warranties and undertakings set out in Annex 3 (Representations and Warranties in connection with China Connect Shares), in connection with any Transaction entered into under this Master Confirmation Agreement and as part of the terms of the Transaction constituted by the relevant Confirmation, Counterparty also represents and warrants to Beevest as of the Trade Date, the Effective Date and each day on which Termination Notice is delivered and payment is made by Counterparty that:

(a)at the time of entering into the Transaction, it has no intention to make a general offer for the Shares of the Issuer at any time prior to the Termination Date;

(b)it is a “professional investor” (as defined in the SFO and any rules made under the SFO) and has such knowledge, experience and expertise in financial and business matters as to be capable of evaluating the merits, risks and suitability of the Transaction, and it is able to bear the economic risk of the Transaction;

(c)it has fully disclosed to Beevest its reasons and objectives for entering into the Transaction, and these constitute and constituted a genuine and legitimate business and commercial purpose which is in its best commercial interest;

(d)it is entering and has entered into the Transaction in the ordinary course of its business and is not entering and has not entered into the Transaction as a wager or bet;

(e)it has taken independent legal advice with regard to the Transaction and to the warranties, representations and undertakings made herein and its obligations under all applicable laws and regulations in connection with the Transaction;

(f)other than Transactions under this Agreement, Counterparty shall not, and shall ensure that no shareholder nor any of their respective Affiliates shall, / none of its Affiliates shall, prior to the amounts payable by Counterparty under the outstanding Transactions under this Master Confirmation Agreement being paid in full, enter into Equity Financing relating to the Shares without Beevest’s prior written consent;

(g)notwithstanding any other provision of the Agreement, Counterparty shall not trade, carry on any business, own any assets (other than Shares) or incur any liabilities except for any liabilities under this Agreement and professional fees and administration costs in the ordinary course of business of an investment or holding company without the prior written consent of Beevest; and

(h)its board of directors (or, if not applicable, the equivalent governing body) has approved its entry into of the Transaction and the performance by it of its obligations under the Transaction.

For the purpose of Sections 3 and 5(a)(iv) of the Agreement, each of the above representations and (to the extent applicable) each of the representations set out in Annex 3 (Representations and Warranties in connection with China Connect Shares) constitutes an Additional Representation (as defined in the Agreement).


Additional definitions and interpretation:

In this Master Confirmation Agreement:

“Blocking Event” means, in respect of any day, any of the following events:

(a)a Margin Call:

(i)is capable of being made pursuant to Credit Support Provisions above; or

(ii)has been made pursuant to Credit Support Provisions above but the required Margin Call Amount has not been transferred in accordance with that provision; and

(b)a Potential Event of Default or Event of Default or (if applicable) an Additional Termination Event under this Agreement has occurred and is continuing with respect to Counterparty.

“Cash” means cash denominated in the Settlement Currency and [HKD and USD]

“Equity Financing” means any margin loan, equity derivative, exchangeable or convertible debt, stock loan, repo or other similar equity-related financing, investment, hedging or monetisation transaction in respect of or relating to the Shares or instruments referencing the Shares or the Issuer.

“Transaction Costs” means, in relation to any Share in respect of which a Hypothetical Broker Dealer, acting in a commercially reasonable manner, could establish Hedge Positions in respect of the Transaction, any brokerage commissions, stock exchange or clearing system charges, levies or other similar charges that would be incurred by such Hypothetical Broker Dealer in connection with its acquisition, holding, disposal, liquidation or realisation of such Shares, as determined by the Hedging Party in good faith and in a commercially reasonable manner.

“Transaction Taxes” means, in relation to any Share in respect of which a Hypothetical Broker Dealer, acting in a commercially reasonable manner, could establish Hedge Positions in respect of the Transaction, any transfer or registration stamp duties or taxes, capital gains taxes, withholding taxes or similar taxes but excluding any tax on net income or similar taxes, that would be incurred by such Hypothetical Broker Dealer in connection with, or as a consequence of, the acquisition, holding, disposal, liquidation or realisation of any such Shares, as determined by the Hedging Party in good faith and in a commercially reasonable manner.

“Break Funding Cost” means, unless otherwise agreed by Parties, in relation to a Transaction, the difference between (a) assuming the Transaction was not early terminated, Fixed Amount payable by Party B calculated on the basis of the scheduled Termination Date, and (b) in the event of an early termination, Fixed Amount payable by Party B calculated on the basis of the Early Termination Date.



IN WITNESS WHEREOF the parties have executed this document with effect from the date first specified on the first page of this document.


Beevest Wealth Management  Limited

 (中诚财富管理有限公司)

By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


[Insert full legal name of Party B or full name of Party B as shown in the Passport or ID]

By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


ANNEX 1

TRANSACTION SUPPLEMENT FOR SHARE SWAPS

This Transaction Supplement is entered into between Party A and Party B listed below on the Trade Date set forth below.

The purpose of this communication is to confirm the terms and conditions of the Share Swap Transaction entered into between Beevest Wealth Management  Limited（中诚财富管理有限公司） (“Party A” or “Beevest”) and [Insert full legal name of Party B or full name of Party B as shown in the Passport or ID] (“Party B” or “Counterparty”) on the Trade Date specified below (the “Transaction”). This Transaction Supplement supplements, forms part of and is subject to the Master Confirmation Agreement (“MCA”) dated as of [Insert Date] between us, as may be amended and supplemented from time to time, and together constitutes a “Confirmation” as referred to in the Agreement between us, as amended and supplemented from time to time.

For the avoidance of doubt, if there is any amount due and payable by Party B but has not been paid or any obligation has not been fully discharged by Party B under this Agreement, or any Event of Default, Potential Event of Default or Additional Termination Event has occurred in relation to Party B and is continuing, any payment, repayment or any other obligation Beevest may otherwise have under this Agreement (including any payment, repayment or other obligation under different Transactions) shall be suspended until none of such circumstances exists with respect to Party B.

The terms of the open market equity finance Share Swap Transaction to which this Transaction Supplement relates are as follows:

General Terms:

Trade Date:     [●]

Effective Date:     [●]

Termination Date:     The final Cash Settlement Payment Date or, if later, the final Dividend Payment Date.

Shares:


     [Insert full title, class and/or par value of the Shares and any other identification number or reference for the Shares] of [insert full legal name of the Issuer of the Shares]

Exchange:     [Shanghai Stock Exchange] / [Shenzhen Stock Exchange]

Type of Return (payment of dividend):     Type of Return is Total Return, which means that, on each Dividend Payment Date, the Equity Amount Payer will pay to the Equity Amount Receiver the relevant Dividend Amount.

Hedging Party:     Beevest.

Settlement Currency:     Hong Kong Dollar (“HKD”)

Local Currency:      The lawful currency of the PRC deliverable in in Hong Kong (“CNH”)

Business Day:     A day (excluding Saturday and Sunday) on which banks and foreign exchange markets are open for business in (a) Hong Kong and (b) (in the case of Shares listed at Shanghai Stock Exchange) Shanghai or (in the case of Shares listed at Shenzhen Stock Exchange) Shenzhen.

Initial Exchange Amount payable by Party B:

Party B Initial Exchange Amount:     HKD [Insert amount denominated in Settlement Currency]

Party B Initial Exchange Date:     Effective Date

Final Exchange Amount payable by Party A:

Party A Final Exchange Amount:     An amount equal to the Party B Initial Exchange Amount.

Party A Final Exchange Date:     Termination Date, without regard to the reference to “the Final Dividend Payment Date” therein.

Equity Amount Payable:

Equity Amount:     An amount calculated pursuant to the formula below, and converted, if applicable, in accordance with the FX Provisions:

 [ ( Final Price – Intial Price ) / Initial Price ]× Equity Notional Amount



Equity Amount Payer:     Beevest.

Equity Amount Receiver:     Counterparty

Payment of Equity Amount:     If the Equity Amount determined by the Calculation Agent as of the relevant Valuation Time on the Valuation Date pursuant to the formula above is a positive number, then the Equity Amount Payer will pay (in addition to any other amounts payable by that Equity Amount Payer) to the Equity Amount Receiver the Equity Amount on the Cash Settlement Payment Date.

If the Equity Amount determined by the Calculation Agent as of the relevant Valuation Time on the Valuation Date pursuant to the formula above is a negative number, then the Equity Amount Receiver will pay (in addition to any other amounts payable by that Equity Amount Receiver) to the Equity Amount Payer the absolute value of the Equity Amount on the Cash Settlement Payment Date.

Number of Shares:     [●]

Initial Price:     Initial Price (excluding commissions) plus Commission Amount, and converted, if applicable, in accordance with the FX Provisions, being CNH [●].

Initial Price (excluding commissions):     CNH [●]

Commission Amount:     [●]% of Initial Price (excluding commissions).

Final Price:     A price per Share as determined by Calculation Agent on the Valuation Date or (as applicable) during the Final Execution Period pursuant to the MCA, the commissions as referred to in the provision of Final Price in the Master Confirmation Agreement is [●]% of the effective price per Share.

Equity Notional Amount:     On any day, the product of the Number of Shares as of that day, the Initial Price and Initial FX Rate (if Initial FX Rate is not otherwise specified, as converted, if applicable, in accordance with the FX Provisions).

The Equity Notional Amount with respect to the Trade Day is HKD [●].

Valuation Date:     [●]

Valuation Time:     In respect of the Valuation Date or each day in the Final Execution Period, each of the times as determined pursuant to the MCA.

Fixed Amount Payable:

Fixed Rate Payer:      Counterparty.

Fixed Rate Receiver:     Beevest. Unless otherwise agreed by Parties, any Fixed Amount paid to the accounts of [●] or [●](acting as the agent of Beevest) shall be deemed as payment to Beevest.

Fixed Amount:     With respect to a Fixed Rate Payer Payment Date, an amount calculated pursuant to the formula below:

Notional Amount × Fixed Rate × Fixed Rate Day Count Fraction for the Calculation Period (provided that, to the extent applicable, for the purpose of determining the Fixed Amount, if Beevest and Counterparty has otherwise agreed on the actual number of days in a Calculation Period, such agreement on the number of days as agreed by Beevest and Counterparty shall prevail),

and converted, if applicable, in accordance with the FX Provisions.

Calculation Period:     With respect to a Fixed Rate Payer Payment Date, the date from (and including) the first day in the calendar month in which the Fixed Rate Payer Payment Date falls to (and including) the last day in such calendar month, provided that the first Calculation Period shall start from (and including) Effective Date and the last Calculation Period shall end at (and excluding) Termination Date.

Payment of Fixed Amount:     The Fixed Amount Payer will pay (in addition to any other amounts payable by that Fixed Amount Payer) to the Fixed Amount Receiver the Fixed Amount on or before 11 a.m. (Hong Kong time) of the relevant Fixed Rate Payer Payment Date.

Notional Amount:     The maximum financing amount agreed by Beevest and Counterparty for this Transaction, being HKD [●].

Fixed Rate Payer Payment Dates:     The last Business Day in each calendar month during the term of the Transaction, provided that the first Fixed Rate Payer Payment Date shall be [●] and the last Fixed Rate Payer Payment Date shall be Termination Date.

Fixed Rate:     [●] per annum, provided that if there is any material change to the overnight rate of interest for HKD which appears on the Reuters Screen HKAB HIBOR Page (or, alternatively, any other source, that is generally accepted by the market participants, as chose by the Calculation Agent), as determined by the Calculation Agent in good faith and commercially reasonable manner, the Calculation Agent shall have the right but not the obligation to adjust the Fixed Rate to account for such change.

Fixed Rate Day Count Fraction:     Actual/360.

Structuring Fee payable by Party B:

Payment of Structuring Fee:     In addition to any payments that may be due under this Transaction, Party B shall pay the Structuring Fee to Party A on the Party B Initial Exchange Date.

Structuring Fee:     [●].

FX Provisions:

FX Provisions:     If the currency in which any Dividend Amount or Final Price is calculated or determined is not HKD, the Calculation Agent shall determine the value of that amount or price in HKD, taking into consideration all available information that it considers relevant, which information shall include the rate(s) of exchange which it determines would apply if that amount or price were converted into HKD  by a Hypothetical Broker Dealer acting in a commercially reasonable manner.

Initial FX Rate:     The Initial FX Rate the Calculation Agent uses to convert the Local Currency into Settlement Currency for the purpose of determining the Equity Notional Amount is [●].

 [Credit Support Provisions:

If, as of any Scheduled Trading Day, the Calculation Agent determines that the LTV Ratio is equal to or below the Margin Call Level (each such day, a “Margin Call Date”), the Calculation Agent may deliver a Margin Call Notice to Counterparty requiring Counterparty to transfer an amount equal to the Margin Call Amount calculated pursuant to the Credit Support Provisions in the MCA.

Failure by Counterparty to transfer Margin Call Amount in time or failure to provide Beevest Evidence of Margin Transfer in time (timing requirements are set out in the MCA) will trigger an Additional Termination Event and therefore give Beevest the right to early terminate this Transaction in part or in full.

Counterparty may also request, on a Scheduled Trading Day, for the return of the Margin Return Amount pursuant to the Credit Support Provisions in the MCA if (a) the LTV Ratio on such Scheduled Trading Day and the LTV Ratio on each of the [three] consecutive Schedule Trading Days immediately preceding such date is greater than the Margin Return Level; and (b) other relevant conditions as set out in the MCA have been satisfied, each as determined by the Calculation Agent.

Initial LTV Level:     [Insert percentage] per cent.

Margin Call Level:     [Insert percentage] per cent.

Margin Return Level:     [Insert percentage] per cent.][     To delete if Credit Support Provisions are not applicable pursuant to the terms of the Master Confirmation Agreement. ]

LTV Ratio:     As of any date, the ratio (expressed as a percentage, and subject to a minimum of zero) calculated as the quotient of:

( A + B + C ) / ( A + B )


where:

“A” means the Initial Exchange Amount;

“B” means the Notional Amount; and

“C” means with respect to a day during the term of the Transaction, the amount, if any, that would be payable by one party to the other, determined by the Calculation Agent as being an amount equal to the difference between (a) the sum of the Equity Amount, any accrued but unpaid Dividend Amount(s) and any Cash Collateral Balance, and (b) the sum of any accrued but unpaid Fixed Amounts and any other amounts that are payable (and reman unpaid) by Party B to Party A under or in connection with the Transaction as of the relevant day, on the basis that:

(i)     for the purpose of determining the value of “C” only:

(a)the immediately previous Scheduled Trading Day shall be the Valuation Date;

(b)the Final Price shall be calculated by the Calculation Agent by reference to the Closing Price as of the Valuation Date,

 (ii)     the amount that that would be payable by Party A to Party B shall be expressed as a positive number; and the amount that would be payable to Party A by Party B shall be expressed as a negative number; and

(iii)     if applicable, such amount shall be converted in accordance with the FX Provisions.


Cash Collateral Balance:     At any time, the aggregate Margin Call Amounts that have already been received by Beevest minus the aggregate Margin Return Amounts that have been paid by Beevest at that time.

Settlement Terms:

Cash Settlement:     Applicable. On the Cash Settlement Payment Date, in addition to any other payments that may be due under this Confirmation (e.g., accrued but unpaid Fixed Amount), an amount equal to the Equity Amount shall be made by the relevant Party pursuant to the terms of this Confirmation and no Party is required to deliver the relevant Shares in connection with settlement of this Transaction.

Cash Settlement Payment Date:     [●] Currency Business Days following the Valuation Date or, if later, the Final Execution End Date.

Account Details:

Account for Payments to Party A:     [●].

Account for Payments to Party B:     [●].

[Other:

Limited Recourse Provisions:     The Parties acknowledge and agree that to the extent Beevest (or its Affiliates) enters into swaps, forward, options or futures or other derivatives transaction(s) (“Hedge Transaction”) with any party in any jurisdiction (“Hedge Counterparty”) as Applicable Hedge Positions to hedge its obligations under this Transaction and the Hedge Counterparty for such transactions fails or delays for any reason (including a default by the local exchange or clearinghouse, a change in laws, rules or regulations applicable to the holding of the relevant securities, options or futures contracts) to make any payment or delivery with respect to a Hedge Transaction, then Beevest’s obligation to pay any amounts due hereunder shall be reduced and/or delayed accordingly.



Beevest Wealth Management  Limited

(中诚财富管理有限公司)


By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


[Insert full legal name of Party B or full name of Party B as shown in the Passport or ID]


By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


ANNEX 2

TRANSACTION SUPPLEMENT FOR SHARE BASKET SWAPS

This Transaction Supplement is entered into between Party A and Party B listed below on the Trade Date set forth below.

The purpose of this communication is to confirm the terms and conditions of the Share Basket Swap Transaction entered into between Beevest Wealth Management  Limited（中诚财富管理有限公司） (“Party A” or “Beevest”) and [Insert full legal name of Party B or full name of Party B as shown in the Passport or ID] (“Party B” or “Counterparty”) on the Trade Date specified below (the “Transaction”). This Transaction Supplement supplements, forms part of and is subject to the Master Confirmation Agreement (“MCA”) dated as of [Insert Date] between us, as may be amended and supplemented from time to time, and together constitutes a “Confirmation” as referred to in the Agreement between us, as amended and supplemented from time to time.

For the avoidance of doubt, if there is any amount due and payable by Party B but has not been paid or any obligation has not been fully discharged by Party B under this Agreement, or any Event of Default, Potential Event of Default or Additional Termination Event has occurred in relation to Party B and is continuing, any payment, repayment or any other obligation Beevest may otherwise have under this Agreement (including any payment, repayment or other obligation under different Transactions) shall be suspended until none of such circumstances exists with respect to Party B.

The terms of the open market equity finance Share Basket Swap Transaction to which this Transaction Supplement relates are as follows:

General Terms:

Trade Date:     [●]

Effective Date:     [●]

Termination Date:     The final Cash Settlement Payment Date or, if later, the final Dividend Payment Date.

Shares:     As specified in the Share Schedule Addendum

Exchange:     As specified in the Share Schedule Addendum

Type of Return (payment of dividend):     Type of Return is Total Return, which means that, on each Dividend Payment Date, the Equity Amount Payer will pay to the Equity Amount Receiver the relevant Dividend Amount.

Hedging Party:     Beevest.

Settlement Currency:     Hong Kong Dollar (“HKD”)

Local Currency:      The lawful currency of the PRC deliverable in in Hong Kong (“CNH”)

Business Day:     A day (excluding Saturday and Sunday) on which banks and foreign exchange markets are open for business in (a) Hong Kong and (b) (in the case of Shares listed at Shanghai Stock Exchange) Shanghai or (in the case of Shares listed at Shenzhen Stock Exchange) Shenzhen.

Initial Exchange Amount payable by Party B:

Party B Initial Exchange Amount:     HKD [Insert amount denominated in Settlement Currency]

Party B Initial Exchange Date:     Effective Date

Final Exchange Amount payable by Party A:

Party A Final Exchange Amount:     An amount equal to the Party B Initial Exchange Amount.

Party A Final Exchange Date:     Termination Date, without regard to the reference to “the Final Dividend Payment Date” therein.


Equity Amount Payable:

Equity Amount:     An amount calculated pursuant to the formula below, and converted, if applicable, in accordance with the FX Provisions:

 × Equity Notional Amount

Equity Amount Payer:     Beevest.

Equity Amount Receiver:     Counterparty

Payment of Equity Amount:     If the Equity Amount determined by the Calculation Agent as of the relevant Valuation Time on the Valuation Date pursuant to the formula above is a positive number, then the Equity Amount Payer will pay (in addition to any other amounts payable by that Equity Amount Payer) to the Equity Amount Receiver the Equity Amount on the Cash Settlement Payment Date.

If the Equity Amount determined by the Calculation Agent as of the relevant Valuation Time on the Valuation Date pursuant to the formula above is a negative number, then the Equity Amount Receiver will pay (in addition to any other amounts payable by that Equity Amount Receiver) to the Equity Amount Payer the absolute value of the Equity Amount on the Cash Settlement Payment Date.

Number of Shares in Basket:     As specified in the Share Schedule Addendum

Number of Basket:      [●]

Initial Price:     As specified in the Share Schedule Addendum.

Final Price:     An amount for the Basket as determined by Calculation Agent on the Valuation Date or during the Final Execution Period pursuant to the MCA, the commissions as referred to in the provision of Final Price in the Master Confirmation Agreement is [●]% of the effective price per Share.

Equity Notional Amount:     On any day, the sum of the values for each Share in the Basket as the product of the Initial Price of each Share and the relevant Number of Shares comprised in the Basket, multiplied by the Number of Baskets and Initial FX Rate (if Initial FX Rate is not otherwise specified, as converted, if applicable, in accordance with the FX Provisions).

The Equity Notional Amount with respect to the Trade Day is HKD [●].

Valuation Date:     [●]

Valuation Time:     In respect of the Valuation Date or each day in the Final Execution Period, each of the times as determined pursuant to the MCA.

Fixed Amount Payable:

Fixed Rate Payer:     Counterparty.

Fixed Rate Receiver:     Beevest. Unless otherwise agreed by Parties, any Fixed Amount paid to the accounts of [●] or [●](acting as the agent of Beevest) shall be deemed as payment to Beevest.

Fixed Amount:     With respect to a Fixed Rate Payer Payment Date, an amount calculated pursuant to the formula below:

Notional Amount × Fixed Rate × Fixed Rate Day Count Fraction for the Calculation Period (provided that, to the extent applicable, for the purpose of determining the Fixed Amount, if Beevest and Counterparty has otherwise agreed on the actual number of days in a Calculation Period, such agreement on the number of days as agreed by Beevest and Counterparty shall prevail),

and converted, if applicable, in accordance with the FX Provisions.

Calculation Period:     With respect to a Fixed Rate Payer Payment Date, the date from (and including) first day in the calendar month in which the Fixed Rate Payer Payment Date falls to (and including) the last day in such calendar month, provided that the first Calculation Period shall start from (and including) Effective Date and the last Calculation Period shall end at (and excluding) Termination Date.

Payment of Fixed Amount:     The Fixed Amount Payer will pay (in addition to any other amounts payable by that Fixed Amount Payer) to the Fixed Amount Receiver the Fixed Amount on or before 11 a.m. (Hong Kong time) of the relevant Fixed Rate Payer Payment Date.

Notional Amount:     The maximum financing amount mutually agreed by Beevest and Counterparty for this Transaction, being HKD [●].

Fixed Rate Payer Payment Dates:     The last Business Day in each calendar month during the term of the Transaction, provided that the first Fixed Rate Payer Payment Date shall be [●] and the last Fixed Rate Payer Payment Date shall be Termination Date.

Fixed Rate:     [●] per annum, provided that if there is any material change to the overnight rate of interest for HKD which appears on the Reuters Screen HKAB HIBOR Page (or, alternatively, any other source, that is generally accepted by the market participants, as chose by the Calculation Agent), as determined by the Calculation Agent in good faith and commercially reasonable manner, the Calculation Agent shall have the right but not the obligation to adjust the Fixed Rate to account for such change.

Fixed Rate Day Count Fraction:     Actual/360.

Structuring Fee payable by Party B:

Payment of Structuring Fee:     In addition to any payments that may be due under this Transaction, Party B shall pay the Structuring Fee to Party A on the Party B Initial Exchange Date.

Structuring Fee:     [●].

FX Provisions:

FX Provisions:     If the currency in which any Dividend Amount or Final Price is calculated or determined is not HKD, the Calculation Agent shall determine the value of that amount or price in HKD, taking into consideration all available information that it considers relevant, which information shall include the rate(s) of exchange which it determines would apply if that amount or price were converted into HKD  by a Hypothetical Broker Dealer acting in a commercially reasonable manner.

Initial FX Rate:     The Initial FX Rate the Calculation Agent uses to convert the Local Currency into Settlement Currency for the purpose of determining the Equity Notional Amount is [●].

[Credit Support Provisions:

If, as of any Scheduled Trading Day, the Calculation Agent determines that the LTV Ratio is equal to or below the Margin Call Level (each such day, a “Margin Call Date”), the Calculation Agent may deliver a Margin Call Notice to Counterparty requiring Counterparty to transfer an amount equal to the Margin Call Amount calculated pursuant to the Credit Support Provisions in the MCA.

Failure by Counterparty to transfer Margin Call Amount in time or failure to provide Beevest Evidence of Margin Transfer in time (timing requirements are set out in the MCA) will trigger an Additional Termination Event and therefore give Beevest the right to early terminate this Transaction in part or in full.

Counterparty may also request, on a Scheduled Trading Day, for the return of the Margin Return Amount pursuant to the Credit Support Provisions in the MCA if (a) the LTV Ratio on such Scheduled Trading Day and the LTV Ratio on each of the [three] consecutive Schedule Trading Days immediately preceding such date is greater than the Margin Return Level; and (b) other relevant conditions as set out in the MCA have been satisfied, each as determined by the Calculation Agent.

Initial LTV Level:     [Insert percentage] per cent.

Margin Call Level:     [Insert percentage] per cent.

Margin Return Level:     [Insert percentage] per cent. ][     To delete if Credit Support Provisions are not applicable pursuant to the terms of the Master Confirmation Agreement. ]

LTV Ratio:     As of any date, the ratio (expressed as a percentage, and subject to a minimum of zero) calculated as the quotient of:

( A + B + C ) / ( A + B )

where:

“A” means the Initial Exchange Amount;

“B” means the Notional Amount; and

“C” means with respect to a day during the term of the Transaction, the amount, if any, that would be payable by one party to the other, determined by the Calculation Agent as being an amount equal to the difference between (a) the sum of the Equity Amount, any accrued but unpaid Dividend Amount(s) and any Cash Collateral Balance, and (b) the sum of any accrued but unpaid Fixed Amounts and any other amounts that are payable (and reman unpaid) by Party B to Party A under or in connection with the Transaction as of the relevant day, on the basis that:

(i)     for the purpose of determining the value of “C” only:

(a)the immediately previous Scheduled Trading Day shall be the Valuation Date;

(b)the Final Price shall be calculated by the Calculation Agent by reference to the Closing Price as of the Valuation Date,

(ii)     the amount that that would be payable by Party A to Party B shall be expressed as a positive number; and the amount that would be payable to Party A by Party B shall be expressed as a negative number; and

(iii)     if applicable, such amount shall be converted in accordance with the FX Provisions.


Cash Collateral Balance:     At any time, the aggregate Margin Call Amounts that have already been received by Beevest minus the aggregate Margin Return Amounts that have been paid by Beevest at that time.

Settlement Terms:

Cash Settlement:     Applicable. On the Cash Settlement Payment Date, in addition to any other payments that may be due under this Confirmation (e.g., accrued but unpaid Fixed Amount), an amount equal to the Equity Amount shall be made by the relevant Party pursuant to the terms of this Confirmation and no Party is required to deliver the relevant Shares in connection with settlement of this Transaction.

Cash Settlement Payment Date(s):     [●] Currency Business Days following the Valuation Date or, if later, the Final Execution End Date.

Account Details:

Account for Payments to Party A:     [●].

Account for Payments to Party B:     [●].

[Other:

Limited Recourse Provisions:     The Parties acknowledge and agree that to the extent Beevest (or its Affiliates) enters into swaps, forward, options or futures or other derivatives transaction(s) (“Hedge Transaction”) with any party in any jurisdiction (“Hedge Counterparty”) as Applicable Hedge Positions to hedge its obligations under this Transaction and the Hedge Counterparty for such transactions fails or delays for any reason (including a default by the local exchange or clearinghouse, a change in laws, rules or regulations applicable to the holding of the relevant securities, options or futures contracts) to make any payment or delivery with respect to a Hedge Transaction, then Beevest’s obligation to pay any amounts due hereunder shall be reduced and/or delayed accordingly.

Share Schedule Addendum

The Basket is composed of the specified Shares of the Issuers listed below in the relative proportions and numbers set out in relation to each Issuer below.

Share     Issuer     Initial Price for the Share (the price includes commissions, which is [●]% of Initial Price (excluding commissions) for the Share)     Initial Price (excluding commissions) for the Share     Ticker     Number of Shares in Basket     Exchange

[●]     [●]     [●]     [●]     [●]     [●]     [Shanghai Stock Exchange] / [Shenzhen Stock Exchange]



Beevest Wealth Management  Limited

 (中诚财富管理有限公司)

By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


[Insert full legal name of Party B or full name of Party B as shown in the Passport or ID]

By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


ANNEX 3

REPRESENTATIONS AND WARRANTIES IN CONNECTION WITH CHINA CONNECT SHARES

Unless otherwise specified in the relevant Transaction Supplement, Counterparty represents and warrants to Beevest as of the Trade Date, the Effective Date and each day on which Termination Notice is delivered by Counterparty that:

(a)it (through its directors, officers, employees, agents, any of its Affiliates or its Affiliates’ directors, officers, employees or agents (as applicable) (each a “Relevant Individual”) or otherwise) is not in possession of any “inside information” (内幕信息, as defined in the PRC Securities Law (as amended)) in relation to the Issuer (“Inside Information”);

(b)it is in compliance with all relevant reporting, notification, disclosure, (to the extent apply to derivatives transactions) lock-up requirements in connection with the Transaction under applicable laws and regulations including but not limited to the PRC Securities Law and other regulatory requirements as published by competent regulators in the PRC (including but not limited to CSRC) from time to time;

(c)it is not breaching any provisions of the PRC Securities Law, the listing rules of the relevant Exchange or any other applicable laws and regulations; and

(d)non-PRC person representations:

(i)it is not (1) a natural person holding a resident identification card or other equivalent government issued identification of the PRC (excluding Hong Kong, Macau and Taiwan) who is not a permanent resident of another jurisdiction or permanent resident of Hong Kong, Macau or Taiwan or (2) a Legal Person Registered in the PRC, or if it is such a person or legal entity, then its entry into the Transaction does not violate the laws and regulations of the PRC including those in relation to foreign exchange control and reporting (each person fall within item (1) or (2), a “Domestic Investor”);

(ii)in the case where the Transaction is entered into by Counterparty as trustee for a trust, interests in the trust are not majority-owned by, and the management decision over the trust is not controlled by, one or more Domestic Investor(s). For the avoidance of doubt, in the case only where a trust's investments are being managed on a discretionary basis by an investment manager, such investment manager shall not be deemed to control such entity for the purposes of this representation by reason only of it being able to control the decision making in relation to the entity’s financial, investment and/or operating policies;

(iii)it is entering into this Master Confirmation Agreement, including each Transaction, as principal and not as an agent of any person or entity.

For so long as Counterparty has any obligation under the Agreement in connection with an outstanding Transaction:

(a)Counterparty acknowledges that Beevest and/or any of its Affiliates may be required to disclose information relating to, among other things, the details of the Transaction or the identities of any party having a legal or beneficial interest in the Transaction as may be required by any relevant governmental or regulatory authorities (including, without limit, CSRC and SAFE) or as may be required under any law, regulation, order or other lawful request, and it agrees to all such related disclosure and hereby waives confidentiality with regard thereto; and

(b)Counterparty agrees to promptly provide Beevest and the relevant governmental or regulatory authorities with such additional information that the relevant governmental or regulatory authorities may require from Beevest and/or its Affiliates from time to time, with regard to the identity and other details of Counterparty or the beneficial owners in respect of the Transaction, including but not limited to (i) the category to which Counterparty belongs (i.e., hedge fund, corporate, individual, pension fund, trust, etc.); and (ii) the source of funding of Counterparty. It agrees that where such information is maintained by any third party on behalf of Counterparty and the trust fund, it shall ensure that appropriate procedures are implemented with such third party to enable the prompt disclosure of such information to Beevest, its nominated Affiliate and/or the relevant governmental or regulatory authorities on request,

where:

“CSRC” means the China Securities Regulatory Commission of the PRC;

“Legal Person Registered in the PRC” means an entity incorporated or organised in the PRC (excluding Hong Kong, Macau and Taiwan);

“PRC” means the People’s Republic of China;

“PRC Securities” means any shares, bonds, warrants or other securities listed on any stock exchange in the PRC (excluding Hong Kong, Macau and Taiwan), securities investment funds quoted in Renminbi or any other financial instruments in which a Qualified Foreign Institutional Investor or a Renminbi Qualified Foreign Institutional Investor may from time to time invest under the laws and regulations of the PRC (excluding Hong Kong, Macau and Taiwan);

“Qualified Foreign Institutional Investor” means a Qualified Foreign Institutional Investor (合格境外机构投资者) defined in the Measures on the Administration of Domestic Securities Investments by Qualified Foreign Institutional Investors (合格境外机构投资者境内证券投资管理办法), as may be amended and supplemented from time to time;

“Renminbi Qualified Foreign Institutional Investor” means a Renminbi Qualified Foreign Institutional Investor (人民币合格境外机构投资者) defined in Pilot Measures on the Administration of Domestic Securities Investments by Renminbi Qualified Foreign Institutional Investors (人民币合格境外机构投资者境内证券投资试点办法), as may be amended and supplemented from time to time;

“SAFE” means the State Administration of Foreign Exchange of the PRC; and

“trust” includes a trust fund or any similar arrangement where the legal title to the trust assets is held by a trustee or legal representative but the beneficial interests in the trust assets are held by beneficiaries; and “trustee” shall be construed accordingly.


ANNEX 4

FORM OF MARGIN CALL NOTICE

To:     [Insert name of Party B] (“Party B”)

From:     Beevest Wealth Management  Limited（中诚财富管理有限公司） (“Party A”)

Dated:     [                   ]

Share Swap and Share Basket Swap Master Confirmation Agreement dated [ ] (the “Master Confirmation Agreement”)

1.We refer to the Master Confirmation Agreement. This is a Margin Call Notice. Terms defined in the Master Confirmation Agreement have the same meaning when used in this Margin Call Notice unless given a different meaning in this Margin Call Notice.

2.The LTV Ratio falls below the Margin Call Level as of [                       ] (the “Margin Call Date”).

3.In accordance with the “Margin Call” paragraph under the “Credit Support Provisions”, you are required to transfer Cash to the account specified below in an aggregate amount of at least [                       ] (the “Margin Call Amount”) by no later than 12:00 noon ([Hong Kong] time) on the Business Day following the Margin Call Notice Date:

Account name:          [●]

Account number:      [●]

Account bank:          [●]

SWIFT Code:          [●]

4.In accordance with the “Margin Call” paragraph under the “Credit Support Provisions”, you are required to deliver Evidence of Margin Transfer to the address specified below by no later than 12:00 noon ([Hong Kong] time) on the  Business Day following the Margin Call Notice Date:

[Email address:     [●]

Fax:               [●]

Attention:          [●]

5.Failure to transfer Margin Call Amount and/or deliver Evidence of Margin Transfer may cause early termination of the Transaction and liquidation of Applicable Hedge Positions.

6.This Margin Call Notice and any non-contractual obligations arising out of or in connection with it are governed by Hong Kong law.


Beevest Wealth Management  Limited（中诚财富管理有限公司）


By: ______________________________
Name: ______________________________
Title: ______________________________
Date: ______________________________


ANNEX 5

FORM OF MARGIN RETURN NOTICE


To:     Beevest Wealth Management  Limited（中诚财富管理有限公司） (“Party A”)

From:     [Insert name of Party B] (“Party B”)

Dated:     [                   ]


Share Swap and Share Basket Swap Master Confirmation Agreement dated [●] (the “Master Confirmation Agreement”)

1.We refer to the Master Confirmation Agreement. This is a Margin Return Notice. Terms defined in the Master Confirmation Agreement have the same meaning when used in this Margin Return Notice unless given a different meaning in this Margin Return Notice.

2.In accordance with the “Margin Return” paragraph under the “Credit Support Provisions”, we request the transfer of Cash in the amount of [                  ] to the following account:

Account name:          [●]

Account number:      [●]

Account bank:          [●]

SWIFT Code:          [●]

3.We confirm that no Blocking Event has occurred and is continuing or would occur with respect to us as a result of the payment of the Margin Return Amount.

4.This Margin Return Notice and any non-contractual obligations arising out of or in connection with it are governed by Hong Kong law.

[Insert name of Party B]

By: ______________________________
Name:
Title:
Date:


ANNEX 6

RELEVANT PROVISIONS REGARDING OTHER DERIVATIVE PRODUCTS

（L.E. OPTIONS AND FUTURES）

This Share Swap and Share Basket Swap Master Confirmation Agreement (this “Master Confirmation Agreement”) also covers other derivative products, such as options and futures. Beevest and the Counterparty agree that the specific conditions and requirements should be discussed and agreed upon before each specific transaction regarding other derivative products. In general, following rights and obligations should be respected:

1.The specific underlying derivative products shall be mutually agreed upon

2.All transactional details specified in this Master Confirmation Agreement and its annexes are generally applicable, other than:

a)The calculation method of equity amount of the underlying derivative products shall follow general market practice and is not limited to the descriptions in this Master Confirmation Agreement

b)Beevest has the right to set the credit support requirement for each underlying derivative product prior to the transaction, and shall inform the Counterparty

c)Beevest has the right to change the credit support requirements during the transaction, and shall inform the Counterparty

3.Beevest will inform the Counterparty about the transaction details via transaction supplements similar to Annex 1 of this Master Confirmation Agreement

`.replace(/\r?\n/g, "<br />");
export default {
  name: "baal",
  components: {
    [Button.name]: Button
  },
  data() {
    return {
      content
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        bwm_mca_eng: "是"
      });
      if (res) {
        this.$router.back();
      }
    }
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "协议详情");
      store.commit("setStepsShow", false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.bwm_mca_eng === "是";
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm
    };
  }
};
</script>

<style lang="less">
.appendix {
  margin-top: @space-2;
  text-align: left;

  .content {
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
