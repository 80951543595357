<template>
  <div class="home">
    <div class="image">
      <van-image
        width="100px"
        style="padding:20px;"
        height="100px"
        fit="contain"
        src="https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/account_processing.png"
      />
    </div>
    <div v-if="addInfo" class="theme-color">
      <div v-if="addInfo.addOpenAccountType === 2 || addInfo.addOpenAccountType === 1">
        补开户审批中
      </div>
      <div v-if="addInfo.addOpenAccountType === 4">补开户已退回</div>
      <div class="tip" v-if="addInfo.addOpenAccountType === 2 || addInfo.addOpenAccountType === 1">
        我们正在认真审批您的资料，审批后我们将短信通知，请留意！
      </div>
      <div class="tip" v-if="addInfo.addOpenAccountType === 4" style="word-wrap: break-word;">
        {{ addInfo.re_oa_step_info }}
      </div>
      <!-- 增加判断条件 -->
      <div
        class="tip"
        v-if="addInfo.add_major_invester === '是' && addInfo.major_invester !== '是'"
      >
        <div>邮寄材料：专业投资者证明原件</div>
      </div>
    </div>
    <div
      v-if="addInfo && addInfo.addOpenAccountType === 4"
      style="text-align: center;margin-top: 20px;"
    >
      <van-button type="primary" style="width: 80%;margin: auto;" @click="handleAddAccount">
        重填补充开户
      </van-button>
    </div>
    <van-button style="width: 80%;margin: 30px auto;" @click="handleGoHome">
      回到首页
    </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Button } from "vant";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import httpAddApi from "../../utils/httpAddApi";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button
  },
  methods: {
    async handleAddAccount() {
      this.$store.commit("setIsAddAccount", true);
      if (this.addInfo) {
        this.$router.push({ path: "/assess/index" });
      }
    },
    handleGoHome() {
      this.$router.push({ path: "/search/index" });
    },
  },
  setup() {
    const addInfo = ref("");
    onMounted(async () => {
      const store = useStore();
      store.commit("setStepsShow", false);
      store.commit("setTitleValue", "查询补开户审批结果");
      store.commit("setTitleLeft", false);
      const addAccountInfo = await httpAddApi.getOpenAccountInfo();
      if (addAccountInfo) addInfo.value = addAccountInfo;
    });
    return {
      addInfo
    };
  }
};
</script>

<style lang="less">
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home {
  padding-left: @space-0;
  padding-right: @space-0;
}
.tip {
  text-align: left;
  margin-top: 20px;
}
</style>
