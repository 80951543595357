<template>
  <div class="appendix">
    <van-image
      fit="contain"
      v-for="item in 8"
      :key="item"
      :src="
        'https://bucket-client-zc.s3-ap-east-1.amazonaws.com/openAccount/image/derivatives_0' +
          item +
          '.jpg'
      "
    />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, Image } from "vant";
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
import httpApi from "../../utils/httpApi";
import httpAddApi from "../../utils/httpAddApi";

export default {
  name: "appendix",
  components: {
    [Button.name]: Button,
    [Image.name]: Image
  },
  data() {
    return {};
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const httprequest = this.isAddAccount
        ? httpAddApi.updateDerivativeAssessmentChoices
        : httpApi.postAsessIndex;
      const res = await httprequest({
        ...data,
        derive_report: "是"
      });
      if (res) {
        this.$router.back();
      }
    }
  },
  setup() {
    const isDisabled = ref(false);
    const submitForm = ref({});
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit("setTitleValue", "“认识一般衍生产品”简报");
      store.commit("setStepsShow", false);
      const httprequest = isAddAccount.value
        ? httpAddApi.getDerivativeAssessmentChoices
        : httpApi.getAsessIndex;
      const result = await httprequest();
      const { data } = result;
      isDisabled.value = data.derive_report === "是";
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isAddAccount,
      isDisabled,
      submitForm
    };
  }
};
</script>

<style lang="less">
.appendix {
  margin-top: @space-2;
  text-align: left;

  .content {
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
