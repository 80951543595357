<template>
  <div class="root">
    <van-cell-group title="">
      <div class="content">
        <div class="title">
          <span>{{ title }}</span>
        </div>
        <div v-html="content" />
      </div>
    </van-cell-group>
    <van-cell-group title="输入名字等同明白和同意以上所有条款">
      <van-field
        v-model="major_confirm"
        label="签署人"
        maxlength="100"
        label-width="3em"
        clearable
        input-align="right"
        placeholder="请输入您的姓名"
      />
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="!major_confirm.trim()">
        {{ isAddAccount ? "下一步" : "下一步（投资风险取向）" }}
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, CellGroup, Cell, Field } from "vant";
import { useStore } from "vuex";
import { onMounted, ref, computed } from "vue";
import httpApi from "../../utils/httpApi";
import httpAddApi from "../../utils/httpAddApi";

const title = "客戶确认及声明";
const content = `本人声明及确认本人已阅读、完全明白及接受向本人提供的“被视为‘专业投资者’所涉及的风险与后果”及“客户协议书条款及细则”的内容。
本人确认及确定已获告知本人有权透过最少提前（14）天向香港中诚证券有限公司（“香港中诚证券”）发出的书面通知，随时就相关产品或市场或当中的任何部分要求撤销被视为专业投资者。本人确定本人不反对被视为专业投资者。
本人确定所提供的资料均为真实准确。本人承诺，当本人向香港中诚证券所提供的资料变得不再准确或以任何方式出现变化时，本人将立即通知香港中诚证券。本人并承诺按香港中诚证券要求向其提供足以证明本人具备专业投资者资格的所需证据。此外，本人每年应向香港中诚证券提供确认书及相关证明文件，以确保本人继续符合《证券及期货（专业投资者）规则》之下被视为专业投资者的一切必要条件。倘若本人无法在本人账户开立日期周年日之前向香港中诚证券提供有关资料，本人确认香港中诚证券有权随时停止将本人视为专业投资者。
本人确定已获清楚告知同意被视为专业投资者所涉及的风险和后果以及就撤销被视为专业投资者所拥有的相关权利。本人完全明白以上内容，且本人希望及同意被视为专业投资者。
`.replace(/\r?\n/g, "<br />");

export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Cell.name]: Cell
  },
  data() {
    return {};
  },
  methods: {
    async next() {
      if (!this.major_confirm) return;
      const httprequest = this.isAddAccount
        ? httpAddApi.updateMajorConfirmInfo
        : httpApi.postMajorOptionStepThree;
      const result = await httprequest({
        major_confirm: this.major_confirm
      });
      if (this.isAddAccount) {
        const assessRes = await httpAddApi.getRiskTropismAssessResult();
        if (!assessRes) return;
        if (this.isAddAccount) await this.$api.submitLog({ type: "submitAddMajorConfirmInfo" });
        if (assessRes.data.risk_tropism_assess === "进取") {
          this.$router.push({ path: "/riskDisclosure/step_one" });
        } else {
          this.$router.push({ path: "/riskOrientation/step_one" });
        }
      } else {
        const res = await this.$api.getHasFinishedRiskTropism();
        if (result) {
          if (res.data.hasFinishedRiskTropism) {
            this.$router.push({ path: "/riskOrientation/step_two" });
          } else {
            this.$router.push({ path: "/riskOrientation/step_one" });
          }
        }
      }
    },
    before() {
      this.$router.back();
    }
  },
  setup() {
    const major_confirm = ref("");
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit("setTitleValue", "风险认知");
      if (isAddAccount.value) {
        store.commit("setStepsValue", ["风险认知(2/4)", "提交资料"]);
        store.commit("setStepsActive", 0);
      } else {
        store.commit("setStepsValue", ["填写资料", "风险认知(2/4)", "提交资料"]);
        store.commit("setStepsActive", 1);
      }
      const httprequest = isAddAccount.value
        ? httpAddApi.getMajorConfirmInfo
        : httpApi.getMajorOptionStepThree;
      const result = await httprequest();
      if (result) {
        major_confirm.value = result.data.major_confirm;
      }
    });
    return {
      title,
      isAddAccount,
      content,
      major_confirm
    };
  }
};
</script>

<style lang="less">
.root {
  text-align: left;

  .content {
    margin-top: @space-2;
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }

  .title {
    text-align: center;
  }

  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
