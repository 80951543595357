import { createApp } from 'vue';
import { Toast } from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
import util from './utils/util';
import api from './utils/httpApi';
import addapi from './utils/httpAddApi';

const pathName = window.location.pathname;
if (pathName === '/login' || pathName === '/') window.sessionStorage.removeItem('vuex');

const app = createApp(App);
/**
 * 通用函数
 */
app.config.globalProperties.$util = util;
app.config.globalProperties.$api = api;
app.config.globalProperties.$addapi = addapi;

/** 修改toast默认配置 */
Toast.setDefaultOptions({ duration: 2000, forbidClick: true });
Toast.setDefaultOptions('loading', { forbidClick: true });

app.use(store).use(router).use(Toast).mount('#app');
